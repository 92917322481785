// body, #root, .App{
//   @media only screen and (max-width: $mobile-sm){
//     width: 41rem;
//   }
// }
// .layout-container {
//   min-height: 100vh;
//   position: relative;
// }
// .grid-container{
//   max-width: 1135px;
//   @media only screen and (max-width: $mobile-lg) {
//     max-width: 100%;
//   }
//   @media only screen and (max-width: $mobile-sm) {
//     max-width: 1135px;
//     width: 41rem;
//   }
// }
.grid-container{
  max-width: 71rem;
}
.flex-reverse{
  flex-direction: row-reverse;
}
// #root{
//   height: 100vh;
// }
.site-content {
  &> .grid-container {
    display: flex;
  }
}

.with-margin-right-15 {
  margin-right: 15px;
}

.with-margin-right-30 {
  margin-right: 30px;
}

.with-margin-bottom-15 {
  margin-bottom: 15px;
}

.with-margin-bottom-30 {
  margin-bottom: 30px;
}

.with-border-bottom {
  margin-top: 5px;
  border: solid 2px lightgray;
}
@media only screen and (max-width: 425px){
  .navigator-container .ms-logo-container{
    display: none;
  }
}