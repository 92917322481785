.accordion {
  border: 1px solid white;

  .accordion-row {
    position: relative;
    border-bottom: 5px solid white;
    background: $fdic-primary;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: $fdic-dark-tan;
    }
    
    .accordion-title {
      padding: 15px;
      font-weight: bold;
      font-size: 14pt;
      color: white;

      &:after {
        position: absolute;
        right: 15px;
        top: 7px;
        font-family: 'Material Icons';
        color: white;
        content: "\002B";
        font-size: 24px;
      }
      @media (max-width: $mobile-lg) {
        padding-right: 30px;
      }
    }

    .accordion-content {
      transition: all .3s;
      background-color: $fdic-light-grey;
      display: none;
      height: 0px;
    }

    &.active {
      transition: all .3s ease;
      background: $fdic-dark-tan;
      margin-bottom: 4px;
      &:not(.hide-dash) {
        .accordion-title {
          background: $fdic-dark-tan;
  
          &:after {
            content: "\2212";
          }
        }
      }
      &.hide-dash {
        .accordion-title {
          background: $fdic-dark-tan;
  
          &:after {
            content: "";
          }
        }
      }

      .accordion-content {
        display: block;
        height: auto;
        padding: 15px;
        transition: all .3s;
        border: 1px solid lightgray;
        border-top: none;
      }
    }
  }
}