.games-banner-container {
  padding-left: 3.5rem;
  @media only screen and (max-width: $mobile-sm){
    padding-left: 0rem;
    text-align: center;
    h1{
      width: 100%;
      text-align: center;
    }
  }
}
.games-banner{
  height: 375px;
  background: url('../../images/public/games-banner.jpg') no-repeat;
  background-size: 100%;
  background-position: bottom;
  @media only screen and (max-width:$mobile-sm){
    background: none;
    height: auto;
    margin: .5rem;
  }
}
.games-banner-container h1 {
  margin-bottom: .5rem;
  margin-top: 1.5rem;
}
.games-banner-container span {
  font-size: 1.7rem;
  font-weight: 500;
  position: relative;
  @media only screen and (max-width:$mobile-sm){
    font-size: 1.5rem;
  }
}
.game-link {
  &:visited {
    color: white
  }
}
.navigator-container{
  @media only screen and (max-width:$mobile-sm){
    text-align: center;
  }
}
.editable-element .table tr td{
  padding-top: .55rem;
  padding-bottom: .55rem;
}
// Games banner main buttons with dropdown 
.games-banner-buttons{
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  position: relative;
  @media only screen and (max-width:$mobile-sm){
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.games-banner-buttons i{
  top: 0px;
  position: absolute;
  font-weight: bold;
  font-size: 1.6rem;
}
.games-banner-buttons a{
  display: block;
  text-decoration: underline;
  text-transform: none;
  padding: .35rem 3rem .35rem 2rem;
  background-color: $fdic-primary;
  border-radius: .25rem;
  transition: all .3s;
  @media only screen and (max-width:$mobile-sm){
    text-align: center;
  }
}
.games-banner-buttons a:last-child{
  margin-left: 1rem;
  padding: .35rem 2rem;
  @media only screen and (max-width:$mobile-sm){
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
.games-banner-buttons a:hover{
  background-color: $fdic-alternate-primary;
  transition: all .3s;
  color: #fff;
}
.games-banner-buttons a span{
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}
.animeOpen{
  display: block;
  position: absolute;
  top: 3rem;
  left: 0;
  margin: 0;
  padding: 1rem;
  background-color: $fdic-primary;
  height: auto;
  overflow: visible;
  transition: all ease-in-out .3s;
  transform-origin : 100% 0 0;
  border-radius: 0;
}
.animeOpen::before {
  content: "";
  top: -1rem;
  left: 87px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17.5px 25px 17.5px;
  border-color: transparent transparent $fdic-primary transparent;
}
.animeHide{
  position: absolute;
  top: 2.4rem;
  left: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out .3s;
}
.games-list {
  display: flex;
  flex-direction: row;
  z-index: 1;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.38);
  @media only screen and (max-width:$mobile-sm){
    flex-direction: column;
    z-index: 1;
  }
}
.games-list li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.games-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.games-list li a{
  background: none;
  margin-left: 0 !important;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  @media only screen and (max-width:$mobile-sm){
    text-align: left;
  }
}
.games-list i{
  top: auto;
  font-weight: normal;
  margin-left: -30px;
  position: absolute;
  font-weight: bold;
  font-size: 1.6rem;
}
ul[class^="ls-col-"] li:last-child {
  border: none;
}
ul[class^="ls-col-"] {
  margin: 0 .5rem !important;
}
  // Games banner main buttons with dropdown ends
  
  
.games-wrapper .games-title{
  text-align: center;
  margin: 3.5rem;
  @media (max-width: $mobile-lg) {
    padding-top: 2rem;
    margin: 2rem;
  }
}
.other-products-container{
  width: 48.5%;
  height: auto;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 2rem;

  .txt-button a{
    text-align: center;
  }
  @media only screen and (max-width:$mobile-sm){
    a.footer-cartoon{
      display: none;
    }
    width: 100%
  }
}
.txt-button a{
  display: block;
  text-decoration: none;
  text-transform: none;
  padding: .35rem 1rem;
  background-color: $fdic-primary;
  border-radius: 25px;
  transition: all .3s;
  color: #fff;
  width: 75%
}
.txt-button a:hover{
  background-color: $fdic-alternate-primary;
  transition: all .3s;
  color: #fff;
}
.txt-button a span{
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}
a.footer-cartoon {
  text-align: right;
  width: 63%;
  position: relative;
  top: 1.1rem;
  background-image: url('../../images/public/More_Products.png');
  background-repeat: no-repeat;
  background-size: contain;
}
