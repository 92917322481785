// Element overrides
html {
  font-family: $general-font-family;
}

h1 {
  color: $fdic-primary;
  font-family: $header-font-family;
  font-size: 40px;
  font-weight: 100;
}
h2 {
  color: $fdic-primary;
  line-height: normal;
  font-family: $header-font-family;
  font-size: 35px;
  font-weight: 100;
  @media (max-width: $mobile-lg) {
    font-size: 28px;
  }
}
h3 {
  color: $fdic-primary;
  font-family: $header-font-family;
  font-size: 25px;
  font-weight: 100;
}

h4 {
  font-family: $general-font-family;
  font-size: 20px;
}

h5 {
  font-family: $general-font-family;
  font-size: $plain-text-font-size;
}

p {
  font-family: $general-font-family;
  font-size: $plain-text-font-size;
}

li {
  font-family: $general-font-family;
  font-size: $plain-text-font-size;
}

label {
  font-family: $general-font-family;
}

a {
  font-weight: bold;
  font-family: $general-font-family;
  color: $fdic-primary;
  text-decoration: underline;
  &:hover {
    color: $fdic-alternate-primary;
    text-decoration: underline;
  }
  &.alternate-link {
    color: white;
    &:hover {
      color: $fdic-light-tan;
    }
  }
  &.light-weight {
    font-weight: normal;
  }
}

.usa-button:not(.usa-button--unstyled):not(.usa-button--base):not(.usa-button--normal-disable) {
  background-color: $fdic-primary;
  &:hover {
    background-color: $fdic-alternate-primary;
  }
  &.usa-button--outline {
    border-color: $fdic-primary;
    color: $fdic-primary;
    background-color: white;
    &:hover {
      border: $fdic-alternate-primary;
      color: $fdic-alternate-primary;
    }
    &:disabled {
      border: white;
      color: white;
    }
  }
  &.usa-button--force-add-submit:disabled {
    background-color: #c9c9c9;
  }
  &:disabled {
    background-color: #c9c9c9;
    color: white;
    cursor: default;
  }
}

.usa-button.usa-button--secondary {
  background-color: $fdic-red;
}

.usa-link {
  &:visited {
    color: inherit;
  }
}

.print-link, 
.print-link:hover {
  text-decoration: none;
}

.usa-language-link {
  color: $fdic-primary;
  font-style: italic;
}

// Alignment
.align-center {
  display: flex;
  align-items: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.centered {
  margin-right: auto;
  margin-left: auto;
}

.mobile-centered {
  @media (max-width: $mobile-lg) {
    margin-right: auto;
    margin-left: auto;
  }
}

// Font sizes
.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: $plain-text-font-size !important;
}

.font-size-15 {
  font-size: 15pt;
}

.font-size-15px {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16pt;
}

.font-size-20 {
  font-size: 16pt;
}

.font-size-1-5rem {
  font-size: 1.5rem;
}

.font-size-2rem {
  font-size: 2rem;
}

// Font style
.font-family-merriweather {
  font-family: "Merriweather";
}

// Margin and padding
.no-margin {
  margin: 0;
}

.margin-0-auto {
  margin: 0 auto;
}

.no-padding {
  padding: 0px;
}

.mobile-no-padding {
  @media only screen and (max-width: $mobile-lg-horizontal) {
    padding: 0;
  }
}

// Display
.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.text-decoration-none {
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap;
}

.full-width {
  width: 100% !important;
  max-width: 100%;
}

.max-width-30 {
  max-width: 30em;
}

.no-border {
  border: none;
}

// Mobile
.hide-mobile {
  @media only screen and (max-width: $desktop-working) { // $mobile-lg-horizontal
    display: none;
  }
}

.hide-desktop-lg {
  @media only screen and (max-width: $desktop-lg) { // $mobile-lg-horizontal
    display: none;
  }
}

.only-mobile {
  @media only screen and (min-width: $desktop-working) { // $mobile-lg-horizontal
    display: none;
  }
}

.overflow-x-scroll-mobile {
  @media (max-width: $mobile-lg) {
    overflow-x: scroll;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.mobile-full-width {
  @media only screen and (max-width: $desktop-working) {
    width: 100% !important;
  }
}

.mobile-no-margin {
  @media only screen and (max-width: $mobile-lg) {
    margin: 0;
  }
}

// Print
.hide-print {
  @media print {
    display: none !important;
  }
}

.print-full-width {
  @media print {
    width: 100%;
  }
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}

.page-break-before-always {
  page-break-before: always;
}
// test for tool 105
// .page-break-before-h3 {
//   h3 {
//     page-break-before: always;
//   }
// }

// For some reason these two fix most issues where the print was spanning two pages
.app:last-child {
  page-break-after: auto;
}

// @media print {
//   div {
//     border: 0.01px solid blueviolet;
//   }
// }

// Miscellanious
.text--with-period {
  &:after {
    content: ".";
    // color: color('secondary-vivid');
  }
}

// Layout
.grid-container-widescreen {
  max-width: 90rem;
}

.text-nowrap {
  white-space: nowrap;
}

.alert-red {
  color: red;
  font-weight: bold;
}

#force-complete-modal {
  min-width: 260px;
}
#modal-close-x-button {
  cursor: pointer;
}

.usa-label.force-cert-label {
  margin-top: 0.8rem;
}
