
.chart {
  .chart--header {
    @include u-margin-bottom(5);
  }

  .chart--title {

  }

  .chart--description {
    @include grid-col(8);
  }
}

.chart--network {
  .chart-canvas {
    height: 100%;
    width: 100%;
  }
  
  .chart-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 30px;
    background: #fafafa;
  }
  
  .chart-legend {
    position: absolute;
    right: 30px;
    top: 50%;
    background: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
  
      .bullet {
        position: relative;
        height: 15px;
        width: 15px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-right: 10px;
        top: 2px;
      }
    }
  }
}