.organization-list {
  .react-tabs__tab {
    width: 25%;
    &.applications-tab {
      &.react-tabs__tab--selected {
        border-top: 5px solid $fdic-cyan
      }
    }
    &.approved-tab {
      &.react-tabs__tab--selected {
        border-top: 5px solid $fdic-primary
      }
    }
    &.suspended-tab {
      &.react-tabs__tab--selected {
        border-top: 5px solid $fdic-dark-tan
      }
    }
    &.denied-tab {
      &.react-tabs__tab--selected {
        border-top: 5px solid $fdic-dark-grey
      }
    }

    
  }
  .react-tabs__tab-panel {
    margin-bottom: 8em;
  }
  .long-info-container {
    border: 1px solid $fdic-dark-grey;
    border-radius: 5px;
    padding: 5px;
  }
}


.rbt-menu.dropdown-menu.show {
  // width: 60% !important;
  .dropdown-item {
    display: block;
    width: 100%;
  }
}

.sessions-chart-container {
  height: 400px;
}