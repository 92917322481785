.account-game-table {
  width: 100%;
  thead {
    text-align: left;
    margin-bottom: 1rem;
    th {
      font-size: $table-header-font-size;
      padding-left: 1.6em;
      border-bottom: $fdic-secondary solid 2px;
      &:not(:first-of-type) {
        border-left: white solid 2px;
      }
      &:nth-child(1) {
        width: 11%;
      }
      &:nth-child(2) {
        width: 45%;
      }
    }
  }
  tbody {
    tr {
      border-bottom: solid lightgrey 1px;
      td {
        padding-left: 2em;
      }
    }
  }
  h3,
  a {
    color: #004b94;
    text-decoration: none;
  }
}

.certificate-wrapper {
  margin-top: 5.5em;
  @media print {
    margin-top: 0px;
  }
}
.certificate {
  border: 8px solid #fff;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.31);
  h1 {
    margin: 0;
    font-size: 38px;
  }
  h3 {
    font-family: Arial;
    font-weight: normal;
    font-style: italic;
    color: #a8246c;
    margin: 0;
    font-size: 24px;
  }
  h4 {
    font-family: Arial;
    color: #58595b;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
  }
  .certificate-input {
    border: none;
    text-align: center;
    width: 100%;
    line-height: 1.3em;
  }
  #cert-org-display-name {
    font-size: 20px;
  }
}

.organization {
  .accordion-row {
    @media print {
      display: none;
    }
  }
  .accordion-row.active {
    @media print {
      display: block;
    }
  }
}

.report-table {
  td,
  th {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    @media print {
      font-size: 10px;
    }
  }
}

.filter-option-group {
  list-style: none;
  padding-left: 0px;
  width: 100%;
}

.admin-account {
  span,
  label {
    font-family: $general-font-family;
    font-size: 16px;
  }
  .custom-tooltip {
    padding: 10px;
    border: 1px solid $fdic-dark-grey;
    border-radius: 2px;
    background: white;
  }
}
