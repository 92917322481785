.resources-page {
  .resource-search-section {
    margin-bottom: -10px;
  }
}
.chloe-container {
  margin-right: -100px;
  line-height: 0;
  @media only screen and (max-width:$mobile-lg) {
    display: none;
  }
}
.resources-description{
  @media only screen and (max-width: $mobile-lg){
    margin: 0;
  }
}
.grid-container.resources-page {

  padding: 0 !important;
}
.resources-wrapper {

  padding: 0 !important;
}
.resources-page {
  justify-content: center;
}
.resources-tabs {
  padding-left: .75em;
  height: 3.75em;
  text-align: center;
  display: flex;
  align-items: center;
}
.react-tabs{
  width: 100%;
}
.react-tabs__tab h3 {
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  text-align: left;
  align-items: center;
  color: $fdic-primary;
  font-weight: 300;
  @media only screen and (max-width:$mobile-lg){
    text-align: center;
    height: 3.3rem;
    font-size: 1.1rem;
    justify-content: center;
  }
  @media only screen and (max-width:375px){
    font-size: .80rem !important;
  }
}
.react-tabs__tab{
  width: 24.77%;
  @media only screen and (max-width:375px){
    width: 24.3% !important;
  }
}
.filter-dropdown ul.filter-options.expanded {
  @media only screen and (max-width: $desktop-working){
    z-index: 1;
    padding: 1rem 0 0 1rem;
    flex-direction: column;
    li{
      border: none;
      margin-bottom: 1rem;
    }
  }
}
@media only screen and (max-width:$mobile-sm){
  .usa-nav__primary button[aria-expanded=true]{
    background-position: 95%;
  }
  .usa-nav__primary button[aria-expanded=false]{
    background-position: 95%;
  }
  .filter-dropdown button{
    width: 21rem;
    @media only screen and (max-width:375px){
      width: 18.5rem !important;
    }
    @media only screen and (max-width:320px){
      width: auto !important;
      min-width: 235px;
    }
  }
}
@media only screen and (max-width: 320px){
  .logo-txt-wrapper{
    display: none;
  }
  .logo-wrapper{
    width: 8rem;
  }
}

.faqs {
  .accordion-content {
    @media (max-width: $desktop-working) {
      overflow-x: hidden;
      overflow: scroll;
    }
  }
  figure.table {
    overflow: hidden;
    overflow-x: scroll;
  }
  table {
    border-spacing: 0px;
    thead {
      tr {
        th {
          background-color: $fdic-primary;
          color: white;
          text-align: center !important;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
    tr{
      th {
        padding-left: 5px;
      }
      td, th {
        padding-right: 1em;
        background-color: $fdic-light-grey;
        border-left: 1px dashed $fdic-dark-grey;
        border-bottom: 1px dashed $fdic-alternate-tan;
        &:last-child {
          border-right: 1px dashed $fdic-alternate-tan;
        }
      }
    }
    @media (max-width: $mobile-lg) {
      overflow: hidden;
      overflow-x: scroll;
    }
  }
}

.helpful-links {
  h3 {
    margin: 0px;
  }
  h4:not(.external-link-header) {
    a {
      font-size: 20px;
    }
    font-size: 16px;
    margin: 0;
    color: $fdic-alternate-primary;
  }
  p:not(.external-link-header)  {
    margin: 0 !important;
    margin-bottom: 2em !important;
  }
}