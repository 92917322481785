#footer #fdic-footer-container.fdic-footer {
  .usa-button {
    background-color: $fdic-dark-tan;
    &:hover {
      background-color: $fdic-gold;
    }
  }
}

.fdic-header, .fdic-footer {
  @font-face {
    font-family: "Open Sans";
    src: url("../../fonts/opensans-regular.ttf");
  }
  
  /*
    resets
  */
  body.usa {
    background-color: #f9f7f3;
  }
  
  /* Breadcrumbs */
  nav#usa-breadcrumbs li + li::before {
    display: inline-block;
    margin: 0 0.6rem;
    height: 0.8em;
    content: "//";
    color: #939393;
  }
  
  nav#usa-breadcrumbs li:last-child a {
    color: #939393;
  }
  
  /* Alerts */
  * + .usa-alert {
    margin-top: 0;
  }
  
  .usa-alert--error {
    background-color: #db001a;
    background-image: none;
  }
  
  .usa-alert--error::before {
    background-color: transparent;
  }
  
  .usa-alert .usa-alert__body {
    padding-left: 0;
  }
  
  .usa-alert a.text-white:hover,
  .usa-alert a.text-white:visited:hover {
    color: #ffebeb;
  }
  
  .usa-alert button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  b,
  strong {
    font-weight: bold;
  }
  
  .usa hr {
    height: 5px;
    outline: none;
    border-top: solid 1px #dcdcdc;
    border-bottom: solid 1px #dcdcdc;
    border-left: 0;
    border-right: 0;
    /* margin-left: 2rem;
    margin-right: 2rem; */
  }
  
  .usa hr.alt {
    height: 1px;
    border-top: solid 1px #979797;
    border-bottom: 0;
    opacity: 0.5;
  }
  
  /* default for all paragraphs */
  main p {
    /* 16px */
    font-size: 1rem;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    color: #444343;
    line-height: 1.6rem;
  }
  
  /*
    Grid
    Our design exceeds the USWDS max width for desktop (768px)
  */
  .usa-banner__inner,
  .usa-banner__content,
  .usa-header--basic .usa-nav-container,
  .usa-header--basic .usa-navbar,
  .usa-header--extended .usa-navbar,
  .usa-footer__primary-container,
  .grid-container {
    max-width: 1388px;
  }
  
  .usa-header--basic .usa-nav-container {
    padding-left: 4px;
  }
  
  .grid-container.margin-x-7 {
    margin-left: 0;
    margin-right: 0;
  }
  
  @media (min-width: 1023px) and (max-width: 1180px) {
    .tablet-portrait\:display-none {
      display: none;
    }
  }
  
  #mapsvg svg {
    background: url("../../images/public/map-texture-masked.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 1px right 0;
  }
  
  #map_tooltip {
    pointer-events: none;
    margin-left: -258px;
    margin-top: -125px;
    width: 250px;
  }
  
  /* Tooltip tail bottom right */
  #map_tooltip:before,
  .keyboard-map-infoboxes .map-infobox:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    border-right: 0;
    margin-left: -17.5px;
    margin-bottom: -25px;
  }
  
  /* Tooltip tail top left */
  /* #map_tooltip:before,
  .keyboard-map-infoboxes .map-infobox:before {
    content: "";
    position: absolute;
    top: 0;
    left: 35px;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-bottom-color: #ffffff;
    border-top: 0;
    border-left: 0;
    margin-left: -17.5px;
    margin-top: -25px;
  } */
  
  .keyboard-map-infoboxes .map-infobox {
    display: none;
    position: absolute;
    background-color: white;
    position: absolute;
    border-radius: 4px;
    margin-left: -60px;
    margin-top: -10px;
    padding: 4px 7px;
  }
  
  .keyboard-map-infoboxes .map-infobox.expanded {
    display: block;
  }
  
  /*
    Accessibility Overrides
  */
  button:not([disabled]):focus,
  input:not([disabled]):focus,
  select:not([disabled]):focus,
  textarea:not([disabled]):focus,
  [contentEditable="true"]:focus,
  [href]:focus,
  [tabindex]:focus,
  iframe:focus,
  .usa-form a:focus,
  .usa-accordion__button:focus,
  .usa-alert__text a:focus {
    outline: 0.125rem dotted #bcac88;
    /*.25rem solid #2491ff is default*/
  }
  
  /*
    Typography
  */
  .usa h1,
  .usa h2,
  .usa h3,
  .usa h4,
  .usa h5,
  .usa h6,
  .usa-prose > h1,
  .usa-prose > h2,
  .usa-prose > h3,
  .usa-prose > h4,
  .usa-prose > h5,
  .usa-prose > h6 {
    font-weight: 100;
  }
  
  footer h1,
  footer h2,
  footer h3,
  footer h4,
  footer h5,
  footer h6 {
    line-height: 1.95rem;
    font-weight: 100;
  }
  
  .usa h1,
  .usa h2,
  .usa h3,
  .usa-prose > h1,
  .usa-prose > h2,
  .usa-prose > h3 {
    /* font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif; */
  }
  
  .usa h4,
  .usa h5,
  .usa h6,
  .usa-prose > h4,
  .usa-prose > h5,
  .usa-prose > h6 {
    /* font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif; */
  }
  
  i {
    color: #fff;
  }
  
  .quote {
    display: flex;
  }
  
  .quote span {
    line-height: 0.2;
  }
  
  /* a:hover i {
    color: inherit;
  } */
  
  .usa-header li ul li,
  .usa-header li ol li {
    padding: 0;
    line-height: 1rem;
  }
  
  .font-header-arrow {
    font-family: "Merriweather Web", Georgia, Cambria, Times New Roman, Times,
      serif;
    /* 18px */
    font-size: 1.125rem;
    font-weight: normal;
    letter-spacing: 1.13px;
  }
  
  .font-heading-xl {
    /* 18px */
    font-size: 1.125rem;
    font-weight: normal;
  }
  
  .font-heading-xl.text-light {
    font-weight: 300;
  }
  
  .font-heading-xxl {
    font-family: "Merriweather Web", Georgia, Cambria, Times New Roman, Times,
      serif;
    /* 32px */
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: normal;
  }
  
  .font-heading-2xl {
    /* 36px */
    font-size: 2.25rem;
  }
  
  .font-heading-3xl {
    /* 48px */
    font-size: 3rem;
    letter-spacing: 1px;
  }
  
  .font-alt-xs {
    /* 14px */
    font-size: 0.875rem;
  }
  
  .font-alt-md {
    /* 16px */
    font-size: 1rem;
  }
  
  .font-size-12px {
    font-size: 0.75rem;
  }
  
  .font-size-13px {
    font-size: 0.8125rem;
  }
  
  .font-size-14px {
    /* 14px */
    font-size: 0.875rem;
  }
  
  .font-size-18px {
    /* 18px */
    font-size: 1.125rem;
  }
  
  .font-size-24px {
    font-size: 1.5rem;
  }
  
  /* Mobile Portrait */
  @media (min-width: 0) and (max-width: 480px) {
    .font-sans-md.mobile\:font-size-14px,
    .mobile\:font-size-14px {
      font-size: 0.875rem;
    }
  }
  
  /* Mobile Portrait */
  @media (min-width: 0) and (max-width: 480px) {
    .font-heading-xl.font-size-20px.mobile\:font-size-18px,
    .font-heading-xl.mobile\:font-size-18px,
    .mobile\:font-size-18px {
      font-size: 1.125rem;
    }
  }
  
  /* Tablet Portrait */
  @media (min-width: 480px) {
    .tablet\:font-size-18px {
      font-size: 1.125rem;
    }
  
    .tablet\:font-size-24px {
      font-size: 1.5rem;
    }
  }
  
  /* Tablet Landscape */
  @media (min-width: 640px) {
    .tablet-lg\:font-size-18px {
      font-size: 1.125rem;
    }
  
    .tablet-lg\:font-size-24px {
      font-size: 1.5rem;
    }
  
    .tablet-lg\:font-size-25px {
      font-size: 1.5625rem;
    }
  
    /* Show underline under active consumers/bankers/analysts tab only on 640px width and up */
    .usa-tab .usa-accordion__button[aria-expanded="true"] {
      text-decoration: underline;
    }
  }
  
  .font-heading-xl.font-size-20px,
  .font-size-20px {
    /* 20px */
    font-size: 1.25rem;
  }
  
  .font-alt-lg {
    /* 24px */
    font-size: 1.5rem;
  }
  
  .font-size-25px {
    /* 25px */
    font-size: 1.5625rem;
  }
  
  /* Mobile Portrait */
  @media (min-width: 0) and (max-width: 480px) {
    .mobile\:font-size-25px {
      font-size: 1.5625rem;
    }
  }
  
  .font-alt-xl {
    /* 32px */
    font-size: 2rem;
  }
  
  .font-alt-4xl {
    font-size: 4rem;
  }
  
  .font-alt-5xl,
  .tablet\:font-alt-5xl {
    font-size: 5.5rem;
  }
  
  /* replace sans with open sans */
  html,
  .font-family-sans {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-3xs {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-2xs {
    /* 14px */
    font-size: 0.875rem;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-xs {
    /* 16px */
    font-size: 1rem;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-sm {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-md {
    /* 18px */
    /* font-size: 1.125rem; */
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-20px {
    /* 20px */
    font-size: 1.25rem;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-lg {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-xl {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-2xl {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .font-sans-3xl {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
  }
  
  .bullet-links li {
    margin-bottom: 0.8rem;
  }
  
  .bullet-links li:last-child {
    margin-bottom: 0;
  }
  
  @media all and (min-width: 1024px) {
    .desktop\:bullet-links li {
      margin-bottom: 0.3rem;
    }
  
    .desktop\:height-380px {
      height: 380px;
    }
  
    .desktop\:width-380px {
      width: 380px;
    }
  
    .desktop\:width-480px {
      width: 30rem;
    }
  }
  
  .list-style-none {
    list-style: none;
  }
  
  a,
  .usa-link {
    text-decoration: none;
  }
  
  a:hover,
  a:active,
  a:visited,
  .usa-link:hover,
  .usa-link:active,
  .usa-link:visited {
    /* color: initial; */
    text-decoration: underline;
  }
  
  a.usa-icon--arrow::after {
    content: "\00a0\0203a";
    font-weight: bold;
    font-size: 110%;
  }
  
  a.usa-icon--arrow:hover::after {
    text-decoration: underline;
  }
  
  a[href^="tel"] {
    text-decoration: inherit;
    color: inherit;
  }
  
  /*
    Hero Carousel
    1388px full width
    14px left & right padding
    7px spacing
    1038px main
    315 small secondary
  */
  .usa-hero--mobile-carousel {
    padding: 0;
  }
  
  /*
    Form
  */
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  /* @media only screen and (min-width: 30em) { */
  .usa-form {
    max-width: 100%;
  }
  
  /* } */
  
  .usa-label {
    line-height: normal;
  }
  
  /* Inputs */
  .usa-input,
  .usa-select {
    height: 40px;
    padding-left: 20px;
    color: #444343;
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif;
    font-size: 16px;
    border-radius: 3px;
    border: none;
    transition: 0.25s ease;
  }
  
  .usa-input.usa-input-search {
    background-image: url("../../images/public/icon-search-blue.svg");
    /* background-size: 1.4rem; */
    background-size: 1.4rem 1.4rem;
    background-position: calc(100% - 5px);
    background-repeat: no-repeat;
    padding-right: 1.5rem;
  }
  
  .usa-form .usa-input {
    max-width: 350px;
    transition: 0.25s ease;
  }
  
  .usa-form .usa-select {
    max-width: 350px;
    background: #fff url("../../images/public/select-arrow-down.png") no-repeat right
      50%;
    transition: 0.25s ease;
    padding-right: 4rem;
  }
  
  .usa-select:hover,
  .usa-select:focus {
    background-image: url("../../images/public/select-arrow-down-active.png");
    transition: 0.25s ease;
  }
  
  /*
    Buttoms
  */
  .usa-button,
  .usa-form .usa-button,
  .usa-button:visited,
  .usa-form .usa-button:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 275px;
    height: 44px;
    /* NOTE: Fallback to USWDS Default */
    color: #ffffff;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-weight: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #7f7044;
    transition: 0.25s ease;
  }
  
  .usa-nav__secondary .usa-search .usa-button[type="submit"] {
    margin-top: 0;
  }
  
  .usa-search .usa-search__submit,
  .usa-search [type="submit"] {
    background-color: #7f7044;
  }
  
  .usa-search .usa-search__submit:hover,
  .usa-search [type="submit"]:hover {
    background-color: #a48923;
  }
  
  /*light chevron acordian*/
  /* .usa-tab-light {
    border: solid 1px #dadada;
  } */
  
  .usa-tab-light .usa-accordion__button {
    color: #003256;
    text-decoration: none;
    background-color: #ffffff;
    background-image: url("../../images/public/arrow-right-blue.svg");
    background-size: 0.8rem;
    background-position: calc(100% - 15px) center;
    border-bottom: 0;
    letter-spacing: 0px;
  }
  
  .usa-tab-light .usa-accordion__button[aria-expanded="true"] {
    color: #003256;
    background-color: #ffffff;
    background-image: url("../../images/public/arrow-down-blue.svg");
    background-size: 1rem;
    background-position: calc(100% - 15px) center;
    border-bottom: 0;
    pointer-events: auto;
  }
  
  .usa-tab-light .usa-accordion__link {
    color: #003256;
    text-decoration: none;
    background-color: #ffffff;
    background-size: 0.8rem;
    background-position: calc(100% - 15px) center;
    border-bottom: 0;
    letter-spacing: 0px;
  }
  
  .usa-tab-light .usa-accordion__heading:not(:first-child) {
    margin-top: 0;
  }
  
  .usa-tab-light .usa-accordion__content {
    background-color: #faf9f6;
  }
  
  .usa-button:hover,
  .usa-form .usa-button:hover,
  .usa-button:visited:hover,
  .usa-form .usa-button:visited:hover {
    color: #ffffff;
    background-color: #a48923;
    transition: 0.25s ease;
  }
  
  .usa-button.usa-button--primary,
  .usa-button.usa-button--primary:visited {
    text-decoration: none;
    color: #ffffff;
    background-color: #003256;
    transition: 0.25s ease;
  }
  
  .usa-button.usa-button--primary:hover,
  .usa-button.usa-button--primary:active {
    /* text-decoration: underline; */
    color: #ffffff;
    background-color: #20509e;
    transition: 0.25s ease;
  }
  
  .usa-button.width-auto {
    display: inline-flex;
    width: auto;
  }
  
  .usa-button.width-full {
    display: inline-flex;
    width: 100%;
  }
  
  .usa-button span {
    display: none;
  }
  
  /*
    Icons
  */
  .usa-icon {
    display: flex;
    overflow: hidden;
    width: 30px;
    height: 30px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    transition: 0.25s ease;
  }
  
  .usa-icon:hover {
    text-decoration: none;
    transition: 0.25s ease;
  }
  
  .usa-icon--gold-circle {
    border-radius: 100%;
    color: #ffffff;
    background-color: #7f7044;
  }
  
  .usa-icon--gold-circle:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #a48923;
  }
  
  .usa-icon--gold-box {
    color: #7f7044;
    background-color: transparent;
    justify-content: center;
    margin: 0;
    padding: 0;
    outline: solid 1px #c4bfad;
  }
  
  .usa-icon--gold-box.font-alt-xs {
    /* 14px */
    /* font-size: 0.875rem; */
    pointer-events: none;
  }
  
  .usa-icon--gold-box.width-auto {
    width: auto;
    /* 11px */
    padding: 0 0.6875rem;
  }
  
  .card .card__content .usa-icon--gold-box {
    background-color: transparent;
  }
  
  .usa-icon--gold-box i {
    color: #7f7044;
    transition: 0.25s ease;
  }
  
  .usa-icon--gold-box i:hover {
    color: #a48923;
    text-decoration: none;
    background-color: transparent;
    transition: 0.25s ease;
  }
  
  /* Flickr icon */
  .usa-icon .fa-circle {
    font-size: 0.7rem;
    margin-left: 1px;
    margin-top: 1px;
  }
  
  /* YouTube icon */
  .usa-icon .fa-play {
    font-size: 1rem;
    margin-left: 3px;
    margin-top: 1px;
  }
  
  #icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Official website banner */
  .usa-banner {
    height: auto;
    background: #fff;
  }
  
  .usa-banner__content {
    padding-top: .7rem;
  }
  
  .usa-banner p {
    /* font-size: 1rem; */
    font-family: "Source Sans Pro Web", "Helvetica Neue", Helvetica, Roboto, Arial,
      sans-serif;
  }
  
  .usa-banner__header-close-text,
  .usa-banner__header-text {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif;
    font-style: italic;
    font-size: 0.74rem;
  }
  
  .usa-banner__button {
    margin-left: 0.25rem;
  }
  
  /* Header/Navigation */
  .usa-header,
  .usa-nav {
    background-color: #003256;
    background-image: none;
    color: #fff;
  }
  
  /* Old site overrides */
  .usa-alert p {
    padding: 0;
  }
  
  div#breadcrumbs,
  div#content,
  div#page_foot {
    max-width: 1366px;
  }
  
  div.left_side {
    width: 74%;
  }
  
  div.right_side {
    width: 21%;
  }
  
  #find_more_info input[type="button"],
  #table_wrapper select {
    background-color: #fff;
  }
  
  #table_filter input[type="text"],
  div.right_side input[type="text"] {
    border: solid 1px #dfdfdf;
  }
  
  .usa-banner p {
    padding: 0;
  }
  
  .usa-header p {
    /* padding: 0; */
  }
  
  .usa-header ul li,
  .usa-footer ul li {
    padding-bottom: 0;
  }
  
  .usa-footer h1,
  .usa-footer h2,
  .usa-footer h3,
  .usa-footer h4,
  .usa-footer h5,
  .usa-footer h6 {
    color: #fff;
    padding-top: 0;
  }
  
  .usa-nav__submenu article h2 {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  /*
    Components
  */
  
  /* Card */
  .card {
    background-color: #fff;
  }
  
  .card.bg-transparent {
    background-color: transparent;
  }
  
  .card.width-281px {
    margin-left: auto;
    margin-right: auto;
  }
  
  .card--chart {
  }
  
  .card--chart .chart-header {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times;
    /* 18px */
    font-size: 1.125rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c4c;
    margin-bottom: 10px;
  }
  
  .card--chart .card__content {
    margin: 0 1.25rem 1.25rem 1.25rem;
  }
  
  .card--chart .card__content .share-text {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times;
    /* 14px */
    font-size: 0.875rem;
    font-weight: normal;
    color: #002c4c;
    font-size: 0.875rem;
    font-style: italic;
  }
  
  .card--news {
  }
  
  .card--news .news-header {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times;
    /* 18px */
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.36px;
    color: #013256;
  }
  
  .card--news .card__content {
    margin: 0 1.25rem 1.25rem 1.25rem;
  }
  
  @media (min-width: 0) and (max-width: 640px) {
    .mobile\:card--inverse {
      background-color: transparent;
    }
  
    .mobile\:card--inverse .card__image {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }
  
    .mobile\:card--inverse .news-header,
    .mobile\:card--inverse .card__content,
    .mobile\:card--inverse .card__content p {
      color: #ffffff;
    }
  
    .mobile\:margin-x-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .mobile\:margin-top-0 {
      margin-top: 0;
    }
  
    .mobile\:margin-top-1 {
      margin-top: 0.5rem;
    }
  
    .mobile\:margin-top-2 {
      margin-top: 1rem;
    }
  }
  
  .card.card--inverse {
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;
    background: none;
    border: none;
    margin: 0;
  }
  
  .card--inverse .news-header,
  .card--inverse .card__content {
    color: #ffffff;
  }
  
  .card--inverse .news-header {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times;
    /* 18px */
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.36px;
  }
  
  .card--inverse .card__content {
    padding: 1.25rem;
  }
  
  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @media all and (min-width: 640px) {
    .visible .card.card--inverse {
      -moz-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
      -webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
      box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 900px) {
    .card.width-281px {
      margin-left: auto;
      margin-right: auto;
    }
  
    .height-125px {
      height: 7.8125rem;
    }
  }
  
  .card .card__image {
    text-align: center;
  }
  
  .card .card__image img {
    width: 100%;
    /* 281px
    max-width: 23.42rem;
    */
  }
  
  .card .card__image img {
    opacity: 1;
    transform: scale(1);
    transition: 0.5s ease;
  }
  
  .card a:hover img,
  .card a:focus img,
  .card:hover .card__image img,
  .card:focus .card__image img {
    opacity: 0.25;
    transform: scale(1.05);
    transition: 0.5s ease;
  }
  
  @media (max-width: 480px) {
    /* .card.card--news .card__image img {
      transform: scale(1) translateY(-25%);
    }
  
    .card.card--news:hover .card__image img,
    .card.card--news:focus .card__image img {
      transform: scale(1) translateY(-25%);
    } */
  }
  
  @media (max-width: 300px) {
    .card.card--news .card__image img {
      transform: scale(1);
    }
  
    .card.card--news:hover .card__image img,
    .card.card--news:focus .card__image img {
      transform: scale(1);
    }
  }
    
  .card a {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
  .card a.usa-icon {
    display: inline-block;
    display: flex;
    background-color: transparent;
    margin: 0 auto;
  }
  
  .img-bg-blue {
    background-color: rgb(4, 82, 137);
  }
    
  .card.card--hero {
    position: relative;
    display: grid;
    grid-row: auto;
    grid-column: auto;
    grid-template-areas: "main";
    overflow: hidden;
    width: 100%;
  }
  
  .card.card--hero:hover,
  .card.card--hero:active,
  .card.card--hero:focus {
    text-decoration: none;
  }
  
  .card--hero .card__image {
    position: relative;
    overflow: hidden;
    /* 183px */
    height: 11.4375rem;
  }
  
  .card--hero .card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  - ie10+ image scaling fix for mobile*/
  /*****************************************************************/
  @media (min-width: 480px) and (max-width: 768px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    .card--hero .card__image {
      position: relative;
      height: 400px !important;
    }
  
    .card--hero .card__image img {
      position: absolute;
      height: 400px !important;
      width: auto !important;
      max-width: inherit !important;
    }
  
    .card--hero .card__content {
      width: 200px;
    }
  }
  
  @media (min-width: 0px) and (max-width: 480px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    .card--hero .card__image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 480px;
      height: auto;
    }
  }
  
  .card--hero .card__type {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    /* 14px */
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #48b0fb;
    text-transform: uppercase;
    margin: 0;
    /* 23px */
    margin-top: 1.4375rem;
  }
  
  .card--hero .card__title {
    color: #013256;
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif;
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 1.3px;
    /* 6px */
    /* 22px - 36px */
    margin: 0 0 0.875rem;
    /*0.375rem 0 0.875rem;*/
  }
  
  .card--hero .card__description {
    color: #505050;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    /* 14px */
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.25px;
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media (min-width: 480px) {
    .card .card__image img {
      width: 100%; /* was auto - TH 6/8/2020 */
    }
  
    .card.card--hero {
      height: 100%;
    }
  
    .card--hero .card__image {
      height: 100%;
      grid-area: main;
      max-height: 100%;
      background-color: rgb(4, 82, 137);
    }
  
    .card--hero .card__image img {
      width: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: 0.5s ease;
    }
  
    .card--hero:hover .card__image img,
    .card--hero:focus .card__image img {
      transform: scale(1.05);
      transition: 0.5s ease;
    }
  
    .card--hero .card__content {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      /* 22px */
      margin: 1.375rem;
      transition: bottom 0.5s ease;
    }
  
    .card--hero:hover .card__content,
    .card--hero:focus .card__content {
      bottom: 50px;
      transition: bottom 0.5s ease;
    }
  
    .card--hero .card__title {
      color: #ffffff;
      font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
        serif;
      font-size: 1.25rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 1.3px;
      transition: margin 0.5s ease;
    }
  
    .card--hero:hover .card__title,
    .card--hero:focus .card__title {
      margin: 0 0 0.375rem;
      transition: margin 0.5s ease;
    }
  
    .card--hero .card__description {
      overflow: hidden;
      color: #fff;
      font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
        Roboto, Arial, sans-serif;
      /* 14px */
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0.25px;
      margin: 0;
      transition: all 0.5s ease;
      /* 22px */
      max-height: 1.375rem;
      /* -42px*/
      margin-bottom: -2.625rem;
    }
  
    .card--hero:hover .card__description,
    .card--hero:focus .card__description {
      transition: all 0.5s ease;
      max-height: 100px;
      margin-bottom: 0px;
    }
  
    .grid-container.margin-x-7 {
      margin-left: 3.5rem;
      margin-right: 3.5rem;
    }
  
    .width-281px {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 17.5625rem;
      max-width: 17.5625rem;
    }
  
    .width-192px {
      min-width: 12rem;
    }
  
    .width-200px {
      min-width: 12.5rem;
    }
  
    .width-300px {
      max-width: 18.75rem;
    }
  
    .width-385px {
      max-width: 24.0625rem;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 768px) {
    .usa-hero--mobile-carousel {
      /* 14px */
      padding: 0.875rem;
    }
  
    .usa-hero--mobile-carousel .glider-track {
      /* important is needed to override glider inline styles */
      width: 100% !important;
      display: -ms-grid;
      display: grid;
      /*
      1360px
      1038px 7px 315px
      76.3235294117647% 0.5147058823529411% 23.161764705882355%
      */
      -ms-grid-columns: minmax(min-content, 64.875rem) 7px 19.8rem;
      -ms-grid-rows: auto 7px auto;
      /* first col is 1038px width, second is 315px*/
      grid-template-columns: minmax(min-content, 74.78386167146974%) auto;
      grid-template-rows: auto;
      /* 7px */
      grid-gap: 0.4375rem;
      grid-template-areas:
        "main sub1"
        "main sub2";
    }
  
    .usa-hero--mobile-carousel .glider-slide {
      overflow: hidden;
      /* important is needed to override glider inline styles */
      width: 100% !important;
    }
  
    .usa-hero--mobile-carousel .glider-slide .card--hero .card__image {
      height: 100%;
    }
  
    .usa-hero--mobile-carousel .glider-slide:nth-of-type(1) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      -ms-grid-column: 1;
      grid-area: main;
      /* 550px */
      /* max-height: 34.375rem; */
    }
  
    .usa-hero--mobile-carousel .glider-slide:nth-of-type(1) .card__content {
      /* width: 100vh; */
    }
  
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(1)
      .card--hero
      .card__image
      img {
      height: 100%;
      object-fit: cover;
    }
  
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(2)
      .card--hero
      .card__image
      img,
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(3)
      .card--hero
      .card__image
      img {
      object-fit: cover;
    }
  
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(2)
      .card--hero
      .card__title,
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(3)
      .card--hero
      .card__title {
      /* 16px */
      font-size: 1rem;
    }
  
    .usa-hero--mobile-carousel .glider-slide:nth-of-type(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      grid-area: sub1;
      /* 272px */
      /* max-height: 17rem; */
    }
  
    .usa-hero--mobile-carousel .glider-slide:nth-of-type(3) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
      grid-area: sub2;
      /* 272px */
      /* max-height: 17rem; */
    }
  
    .dots.glider-dots {
      display: none;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  - ie10+*/
  /*****************************************************************/
  @media all and (min-width: 768px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(2)
      .card--hero
      .card__image
      img,
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(3)
      .card--hero
      .card__image
      img {
      width: auto;
      height: 100%;
    }
  
    .usa-hero--mobile-carousel .glider-slide .card--hero .card__description {
      width: 95%;
    }
  
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(1)
      .card--hero
      .card__content {
      /* width: 64.6875rem; */
    }
  
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(2)
      .card--hero
      .card__content,
    .usa-hero--mobile-carousel
      .glider-slide:nth-of-type(3)
      .card--hero
      .card__content {
      width: 19.6875rem;
    }
  }
  
  .usa-hero {
    background-image: url("../../images/public/large-3up.jpg");
    background-position: top center;
  }
  
  .usa-hero--container {
    position: absolute;
  }
  
  @media (min-width: 1024px) {
    .usa-nav__primary button,
    .usa-nav__primary button:hover,
    .usa-nav__primary button[aria-expanded="true"],
    .usa-nav__primary button[aria-expanded="true"]:hover,
    .usa-nav__primary button[aria-expanded="false"]:hover {
      transition: 0.25s ease;
    }
  }
  
  .usa-navbar {
    border-bottom: none;
  }
  
  /* Header with Golden Top Rule */
  .usa-header {
    border-top: solid 2px #7f7044;
    box-shadow: inset 0 7px 0 #b7ac83;
    -moz-box-shadow: inset 0 7px 0 #b7ac83;
    -webkit-box-shadow: inset 0 7px 0 #b7ac83;
  }
  
  
  
  .usa-nav__primary .usa-accordion__button span {
    color: #fff;
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif;
    font-weight: 100;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    padding-right: 0;
  }
  
  .usa-nav__primary > .usa-nav__primary-item#search {
    display: none;
  }
  
  .usa-header + .usa-section,
  .usa-header + main {
    border-top: 0;
  }
  
  /* Mega Menu Content */
  .usa-nav__submenu,
  .usa-nav__submenu h2,
  .usa-nav__submenu a,
  .usa-nav__submenu a:visited {
    font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
      serif;
    font-weight: 100;
    color: #fff;
    line-height: 1.5rem;
  }
  
  .usa-nav__submenu {
    transition: 0.5s ease;
  }
  
  .usa-nav__submenu article p,
  .usa-nav__submenu article span {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    font-size: 0.8rem;
    line-height: 1.7rem;
    letter-spacing: 0.03rem;
  }
  
  .usa-nav__submenu article ul {
    margin-left: 0;
  }
  
  .usa-nav__submenu-list > li {
    list-style: disc;
    font-size: 0.8rem;
    line-height: 0.5rem;
    letter-spacing: 0.1rem;
  }
  
  /* Mega Menu Link Hovers */
  .usa-nav__submenu a:hover,
  .usa-nav__submenu a:visited:hover,
  .usa-nav__submenu .usa-nav__submenu-item a:hover,
  .usa-nav__submenu .usa-nav__submenu-item a:visited:hover {
    color: #a48923;
    transition: 0.25s ease;
    text-decoration: underline;
  }
  
  .usa-nav__submenu .card a,
  .usa-nav__submenu .card a:visited {
    color: #003256;
  }
  
  .usa-nav__submenu .card a:hover,
  .usa-nav__submenu .card a:visited:hover,
  .usa-nav__submenu .card a:focus {
    color: #3163b6;
  }
  
  .usa-search input[type="search"] {
    font-style: italic;
    letter-spacing: 0.05rem;
  }
  
  .usa-search .usa-button {
    background-image: url("../../images/public/icon-search.svg");
    background-size: 1.4rem;
  }
  
  /* Search Submit Icon */
  .usa-search .usa-search__submit,
  .usa-search [type="submit"] {
    width: 2.3rem;
  }
  
  /* Mega menu - affecting all breakpoints */
  /* TO DO: don't affect mobile */
  header .usa-accordion {
    /* color: #fff; */
  }
  
  header .usa-nav__primary .usa-nav__primary-item > a {
    color: #fff;
    text-decoration: none;
  }
  
  header .usa-nav__primary .usa-nav__primary-item > a:hover,
  header .usa-nav__primary .usa-nav__primary-item > a:visited:hover {
    text-decoration: underline;
  }
  
  /* Adjust overlay dim */
  .usa-overlay {
    z-index: 0;
  }
  
  @media (min-width: 0) and (max-width: 639px) {
    /* Make overlay dim darker on mobile for map popup */
    .usa-overlay.is-visible {
      /* opacity: 0.7; */
    }
  }
  
  /* Logo */
  .usa-logo {
    margin-top: 1.2rem;
    margin-left: 0.8rem;
  }
  
  /* Hamburger Icon */
  .usa-menu-btn .fa-bars {
    font-size: 2rem;
  }
  
  .usa-menu-btn,
  .usa-menu-btn:hover {
    background-color: transparent;
    margin-top: 5px;
    padding-right: 9px;
  }
  
  .usa-nav__close .fa-times {
    font-size: 1.5rem;
  }
  
  /* Menu */
  .usa-nav__primary {
    order: 1;
  }
  
  .usa-nav__secondary {
    order: 2;
  }
  
  .usa-nav__secondary .usa-label {
    margin-bottom: 1rem;
  }
  
  /* Menu Links */
  .usa-nav__primary button {
    text-transform: uppercase;
  }
  
  /* Social links under search */
  .usa-social-link {
    text-align: center;
    padding-top: 16px;
  }
  
  /*
    Footer
  */
  /*
    .usa-footer {
      background-color: #1b1d20;
    }
  */
  .usa-footer p {
    padding: 0;
  }
  
  .usa-footer__primary-section {
    background-color: #1b1d20;
  }
  
  .usa-footer__secondary-section {
    background-color: #1b1d20;
    border-top: solid 2px #fff;
  }
  
  .usa-footer__secondary-section .usa-footer__nav li {
    width: auto;
    margin: 0 auto;
    padding: auto;
    text-align: center;
  }
  
  .usa-footer__primary-link,
  .usa-footer__secondary-link,
  .usa-footer__secondary-section a,
  .usa-footer__secondary-section a:visited {
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    /* NOTE: Fallback to USWDS Default */
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    margin-left: 0;
  }
  
  .usa-footer__secondary-section a:hover,
  .usa-footer__secondary-section a:visited:hover {
    text-decoration: underline;
  }
  
  .usa-footer__secondary-section ul li a {
    /* TO DO - find way to remove left margin from footer links to truly center */
  }
  
  /*
    Carousel
  */
  
  .carousel-previous-button,
  .carousel-next-button,
  .dark .carousel-previous-button,
  .dark .carousel-next-button {
    display: none;
  }
  
  .view-more-link,
  .view-more-link:visited {
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.71 px;
    color: #003256;
  }
  
  .carousel--dark .view-more-link {
    color: #ffffff;
  }
  
  .carousel-controls {
    flex-direction: column;
    margin: 0;
  }
  
  .glider.margin-x-4,
  .glider-track.margin-x-4 {
    margin-right: 0;
    margin-left: 0;
  }
  
  /* glider/carousel */
  
  .glider.disable-scrollbars::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  
  .glider .glider-slide .card {
    height: 100%;
    width: 100%;
    display: block;
  }
  
  .glider .glider--one {
    width: 100%;
  }
  
  .glider.glider--three-wide .card .card__content {
    margin: 0 1.25rem 1.25rem 1.25rem;
  }
  
  .glider.glider--three-wide .card .card__image img {
    width: 100%;
    object-fit: cover;
  }
  
  .glider.glider--three-narrow {
    max-width: 825px;
    margin: 0 auto;
  }
  
  .glider.glider--three-narrow-2 {
    max-width: 939px;
    margin: 0 auto;
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media (min-width: 768px) {
    .carousel-controls {
      flex-direction: row;
      margin: 0 3.5rem;
    }
  
    .glider-track {
      margin-right: 2rem;
      /* margin-left: 2rem; */
    }
  
    .glider.margin-x-4,
    .glider-track.margin-x-4 {
      margin-right: 2rem;
      margin-left: 2rem;
    }
  
    .view-more-link {
      margin-right: 1.875rem;
    }
  
    .carousel-previous-button,
    .carousel-next-button,
    .carousel--dark .carousel-previous-button,
    .carousel--dark .carousel-next-button {
      display: block;
      /* 57px */
      height: 3.5625rem;
      width: 3.5625rem;
      border: none;
      cursor: pointer;
    }
  
    .carousel-previous-button {
      background: url("../../images/public/arrowprevnew.svg") no-repeat;
    }
  
    .carousel--dark .carousel-previous-button {
      background: url("../../images/public/arrow-prev.svg") no-repeat;
    }
  
    .carousel-previous-button:hover,
    .carousel-previous-button:active {
      background: url("../../images/public/arrowprevnewhover.svg") no-repeat;
    }
  
    .carousel-next-button {
      background: url("../../images/public/arrownextnew.svg") no-repeat;
    }
  
    .carousel--dark .carousel-next-button {
      background: url("../../images/public/arrow-next.svg") no-repeat;
    }
  
    .carousel-next-button:hover,
    .carousel-next-button:active {
      background: url("../../images/public/arrownextnewhover.svg") no-repeat;
    }
  }
  
  .dots.glider-dots {
  }
  
  .glider-dot {
    height: 1.8125rem;
    width: 1.8125rem;
    color: #ffffff;
    background: #ffffff;
    border: 1px solid #003256;
    /* 20px 2.5px*/
    margin: 1.5rem 0.15625rem;
  }
  
  .glider-dot--dark .glider-dot {
    height: 1.8125rem;
    width: 1.8125rem;
    color: #ffffff;
    background: transparent;
    border: 1px solid #ffffff;
    /* 20px 2.5px*/
    margin: 1.5rem 0.15625rem;
  }
  
  .glider-dot:focus,
  .glider-dot.active {
    color: #003256;
    background: #003256;
  }
  
  .glider-dot--dark .glider-dot:focus,
  .glider-dot--dark .glider-dot.active {
    color: #ffffff;
    background: #ffffff;
  }
  
  .usa-tab .usa-accordion__button {
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #003256;
    background-image: url("../../images/public/arrow-right-white.svg");
    background-size: 0.8rem;
    background-position: calc(100% - 16px) center;
  }
  
  .usa-tab .usa-accordion__button[aria-expanded="true"] {
    color: #ffffff;
    background-color: #003256;
    background-image: url("../../images/public/arrow-down-white.svg");
    background-size: 1.2rem;
    background-position: calc(100% - 16px) center;
  }
  
  .usa-tab .usa-accordion__heading:not(:first-child) {
    margin-top: 0;
    /* border-top: solid 2px rgba(255, 255, 255, 0.5); */
  }
  
  /*
    Tabs
  */
  .usa-accordion__content {
    overflow: hidden;
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 40em) {
    .tablet\:text-left {
      text-align: left;
    }
  }
  
  @media all and (min-width: 640px) {
    // .bg-microphone {
    //   background-image: url("../../images/public/fdic-about-leadership-bg3.jpg");
    //   background-position: top left;
    //   background-repeat: no-repeat;
    // }
  
    // .bg-careers {
    //   background-image: url("../../images/public/fdic-about-careers-bg.jpg");
    //   background-position: 40% 0;
    //   background-repeat: no-repeat;
    //   max-height: 515px;
    // }
  
    // .bg-initiatives {
    //   background-image: url("../../images/public/fdic-resources-initiatives-bg.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   max-height: 515px;
    // }
  
    // .bg-depositinsurance {
    //   background-image: url("../../images/public/fdic-topic-depositinsurance-bg.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-supervision {
    //   background-image: url("../../images/public/fdic-topics-supervision-bg.png");
    //   background-position: 76% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-lawsregulations {
    //   background-image: url("../../images/public/fdic-resources-lawsregulations.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-resolutions {
    //   background-image: url("../../images/public/fdic-resources-resolutions.jpg");
    //   background-position: 60% 40%;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-tools {
    //   background-image: url("../../images/public/fdic-resources-tools.jpg");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-financialresearch {
    //   background-image: url("../../images/public/fdic-analysis-financialresearch-bg.jpg");
    //   background-position: 50% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-quarterlyprofile {
    //   background-image: url("../../images/public/fdic-analysis-quarterlyprofile-bg.jpg");
    //   background-position: 40% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-natsurvey {
    //   background-image: url("../../images/public/fdic-analysis-natsurvey-bg.jpg");
    //   background-position: 50% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-difference {
    //   background-image: url("../../images/public/fdic-careers-difference-bg.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-employee-benefits {
    //   background-image: url("../../images/public/fdic-careers-employee-benefits-bg.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   min-height: 450px;
    // }
  
    // .bg-interships-careers {
    //   background-image: url("../../images/public/fdic-careers-interships-careers-bg.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   min-height: 446px;
    // }
  
    // .bg-job-opportunities {
    //   background-image: url("../../images/public/fdic-careers-job-opportunities-bg.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-transparency {
    //   background-image: url("../../images/public/fdic-trust-why-bg.png");
    //   background-position: 36% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-sharing {
    //   background-image: url("../../images/public/fdic-trust-sharing-performance-bg.png");
    //   background-position: 40% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-explaining-work {
    //   background-image: url("../../images/public/fdic-trust-explaining-work-bg.png");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    // }
  
    // .bg-access-data {
    //   background-image: url("../../images/public/fdic-access-data-bg.png");
    //   background-position: 40% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-future {
    //   background-image: url("../../images/public/fdic-innovation-future-bg.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-small-business-lending {
    //   background-image: url("../../images/public/fdic-resources-publications-small-business-lending.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    // }
  
    // .bg-initivies-trust {
    //   background-image: url("../../images/public/fdic-about-inititives-trust.jpg");
    //   background-position: 70% 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-resolutions-bank-failures {
    //   background-image: url("../../images/public/fdic-resources-resolutions-bank-failures.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-resolutions-authority-news {
    //   background-image: url("../../images/public/fdic-resources-resolutions-authority-news.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-bank-find {
    //   background-image: url("../../images/public/fdic-resources-tools-bank-find.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-deposit-market-share-report {
    //   background-image: url("../../images/public/fdic-resources-tools-deposit-market-share.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-financial-inclusion {
    //   background-image: url("../../images/public/fdic-about-initiatives-financialinclusion.jpg");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-realestateproperty {
    //   background-image: url("../../images/public/fdic-resources-resolutions-realestateproperty.jpg");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-allianceforeconinclusion {
    //   background-image: url("../../images/public/fdic-about-inititives-allianceforeconinclusion.jpg");
    //   background-position: 0 0;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    // .bg-financialinclusion {
    //   background-image: url("../../images/public/fdic-about-initiatives-financialinclusion.jpg");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   min-height: 400px;
    // }
  
    .usa-tab {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr minmax(215px, 1fr) minmax(215px, 1fr)
        minmax(215px, 1fr) auto 1fr;
      grid-template-columns:
        minmax(0, 1fr) minmax(215px, 1fr) minmax(215px, 1fr) minmax(215px, 1fr)
        auto auto minmax(0, 1fr);
      /* grid-template-columns: minmax(0, 1fr) min-content min-content min-content min-content min-content minmax(0,
      1fr); */
      grid-template-rows: auto;
      grid-template-areas:
        "left header1 header2 header3 header4 header5 right"
        "content content content content content content content";
      /* background-color: #003256; */
      background-image: linear-gradient(to bottom, #003256 63px, #f9f7f3 63px);
    }
  
    .usa-tab .usa-accordion__heading:not(:first-child),
    .usa-tab .usa-accordion__heading {
      margin: 0;
      padding: 0;
    }
  
    .usa-accordion__button {
      letter-spacing: 1.5px;
    }
  
    main .usa-accordion__button[aria-expanded="true"] {
      pointer-events: none;
    }
  
    header .usa-accordion__button[aria-expanded="false"]:hover,
    header .usa-accordion__button[aria-expanded="true"] {
      text-decoration: none;
    }
  
    /*
    .usa-accordion__button[aria-expanded="true"]::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      border-bottom: 1px solid #ffffff;
    }
    */
  
    .usa-accordion__button[aria-expanded="false"] {
      text-decoration: none;
    }
  
    .usa-tab .usa-accordion__heading .usa-accordion__button {
      height: 100%;
      color: inherit;
      text-align: center;
      background: none;
      padding: 1.25rem 2.5rem;
      margin: 0;
    }
  
    .usa-tab .usa-accordion__heading:nth-of-type(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      grid-area: header1;
    }
  
    .usa-tab .usa-accordion__heading:nth-of-type(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      grid-area: header2;
    }
  
    .usa-tab .usa-accordion__heading:nth-of-type(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 4;
      grid-area: header3;
    }
  
    .usa-tab .usa-accordion__heading:nth-of-type(4) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
      grid-area: header4;
    }
  
    .usa-tab .usa-accordion__heading:nth-of-type(5) {
      -ms-grid-row: 1;
      -ms-grid-column: 6;
      grid-area: header5;
    }
  
    .usa-tab .usa-accordion__content {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      -ms-grid-column-span: 6;
      grid-area: content;
      padding: 0;
    }
  }
  
  /*
  Tablet Portrait and lower
  */
  @media all and (max-width: 640px) {
    .glider.tablet\:margin-bottom-0,
    .carousel-controls.tablet\:margin-bottom-0,
    .tablet\:margin-bottom-0 {
      margin-bottom: 0;
    }
  }
  
  /* Tablet Landscape and lower */
  @media all and (max-width: 880px) {
    .carousel-controls.tablet-lg\:margin-bottom-0,
    .tablet-lg\:margin-bottom-0 {
      margin-bottom: 0;
    }
  }
  
  @media all and (max-width: 1024px) {
    .tablet-lg\:text-center {
      text-align: center;
    }
  
    .tablet-lg\:position-relative {
      position: relative;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint - Smallest ≥ 0px & ≤ 1024px */
  /*****************************************************************/
  @media all and (min-width: 0) and (max-width: 1023px) {
    .usa-help .usa-button,
    .usa-help .usa-form .usa-button {
      margin-top: 1rem;
    }
  
    /* .usa-banner__button::after, */
    .usa-banner__button[aria-expanded="false"],
    .usa-banner__button[aria-expanded="true"] {
      background-position: left 287px bottom 8px;
    }
  
    /* Stay Informed Component */
    .usa-stay-informed .usa-button {
      margin-top: 1rem;
    }
  
    .usa-header {
      border-bottom: solid 6px #fff;
    }
  
    
  
    .usa-banner__header {
      min-height: 22px;
    }
  
    .usa-banner__content {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  
    .usa-nav {
      background-image: url("../../images/public/fdic-logo-seal.svg");
      background-repeat: no-repeat;
      background-size: 239%;
      background-position: -478px -126px;
    }
  
    .usa-nav__primary button,
    .usa-nav__primary button:hover,
    .usa-nav__primary a:hover {
      background-color: transparent;
    }
  
    .usa-nav__primary button {
      padding: 1.2rem 1.5rem;
    }
  
    .usa-nav__primary button[aria-expanded="false"] {
      background-image: url("../../images/public/arrow-right-white.svg");
      background-size: 0.8rem;
      background-position: calc(100% - 16px) center;
    }
  
    .usa-nav__primary button[aria-expanded="true"] {
      background-image: url("../../images/public/arrow-down-white.svg");
      background-size: 1.2rem;
      background-position: calc(100% - 14px) center;
    }
  
    .usa-nav__primary .usa-accordion__button span {
      font-size: 1.5rem;
      font-weight: 100;
    }
  
    .usa-nav__submenu {
      padding: 0 0.3rem 1rem;
    }
  
    .usa-nav__submenu .grid-row {
      padding: 0 1rem;
    }
  
    .usa-nav__submenu article h2,
    .usa-nav__submenu-list,
    .usa-nav__submenu .usa-col {
      display: none;
    }
  
    .usa-nav__submenu article p {
      margin-top: 0;
      margin-bottom: 0;
    }
  
    article .usa-nav__submenu-list {
      display: block;
    }
  
    article .usa-nav__submenu-list .usa-nav__submenu-item {
      border-top: none;
      list-style: none;
    }
  
    article .usa-nav__submenu-list .usa-nav__submenu-item a {
      padding-left: 0;
    }
  
    .usa-accordion__button .usa-icon {
      position: absolute;
      height: 37px;
      width: 43px;
      right: 0;
      top: 0;
    }
  
    .light-parent-nav .usa-accordion__button[aria-expanded="true"] span i {
      transform: rotate(180deg);
    }
  
    .usa-search p {
      margin-top: 0;
    }
  
    .usa-search p a,
    .usa-nav__secondary .usa-search .usa-link {
      margin-right: 0;
    }
  
    .usa-search .usa-link:hover {
      color: #a48923;
      text-decoration: underline;
    }
  
    .usa-search input[type="search"] {
      /* max-width: calc(27ch + 6rem); */
    }
  
    .usa-search .usa-search__submit,
    .usa-search [type="submit"] {
      width: 3.5rem;
      margin-bottom: 0;
      height: 40px;
    }
  
    header .usa-nav__primary-item {
      border-top: none;
      border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    }
  
    /* Header Sticky Nav */
    header.position-sticky {
      top: 0;
    }
  
    /* Full width menu instead of partial */
    .usa-nav {
      width: 100%;
      padding: 0 0 12px 0;
    }
  
    .usa-nav__secondary .usa-form,
    .usa-nav__secondary .usa-link {
      display: block;
      color: #fff;
      text-decoration: none;
      font-size: 0.7rem;
      font-weight: 100;
      letter-spacing: 0.1rem;
    }
  
    /* Advanced Search link */
    .usa-nav__secondary .usa-link {
      margin-right: 12px;
      font-family: "Merriweather Web", Georgia, Cambria, "Times New Roman", Times,
        serif;
    }
  
    .usa-logo {
      margin-top: 0.5rem;
      margin-left: 0.8rem;
      height: 32px;
      overflow: hidden;
    }
  
    .usa-logo img {
      max-width: none;
      width: 5rem;
      transition: scale 0.25s;
    }
  
    /* Close Icon */
    .usa-nav__close {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      height: 4rem;
    }
  
    .usa-nav__primary {
      margin-top: 0;
    }
  
    .usa-nav__primary a {
      color: #fff;
      width: 20rem;
    }
  
    .usa-footer__primary-section .usa-footer__primary-content {
      border-top: none;
      border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
    }
  
    /* to do - hide bottom border on last "how can we help" section */
    /*
  .usa-footer .usa-footer__primary-content{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  */
    .usa-footer .grid-row.grid-gap-4 > *,
    .usa-footer .grid-row.grid-gap > * {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  
    .usa-footer .margin-6 {
      margin-bottom: 0;
    }
  
    .usa-footer .margin-6:first-child {
      margin-top: 0;
    }
  
    /* Footer - last section - hide bottom border and reduce padding */
    .usa-footer .margin-6 div:last-child section {
      border-bottom: none;
      padding-bottom: 0;
    }
  
    .usa-footer__nav {
      border-bottom: none;
    }
  
    .usa-footer__secondary-section .usa-footer__nav {
      /* width: 18rem; */
    }
  
    .usa-footer__secondary-section .usa-footer__nav ul {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  
    .usa-footer__secondary-section .usa-footer__nav ul li {
      display: inline;
      text-align: center;
      width: auto;
      margin: 0 auto;
    }
  
    .usa-navbar {
      height: 4rem;
    }
  
    .usa-banner__header {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  
    /* Official website of the US government down arrow */
    /* Option 1 - matches designs */
    /* .usa-banner__button::after, */
    .usa-banner__button[aria-expanded="false"],
    .usa-banner__button[aria-expanded="true"] {
      background-image: url("../../images/public/angle-arrow-down-primary.svg"),
        linear-gradient(transparent, transparent);
      /* background-position: left 287px bottom 8px; */
      background-size: 0.5rem;
    }
  
    .usa-banner__button[aria-expanded="true"] {
      background-image: url("../../images/public/angle-arrow-up-primary.svg"),
        linear-gradient(transparent, transparent);
    }
  
    .usa-banner__button::after {
      background-image: none;
    }
  
    .usa-banner__button[aria-expanded="true"]::after {
      background-color: transparent;
      background-position: right 10px top 5px;
      display: none;
    }
  
    /* Option 2 - move arrow to far right to line up with X when it opens */
    /*
  .usa-banner__button::after,
  .usa-banner__button[aria-expanded=false],
  .usa-banner__button[aria-expanded=true]{
    background-image: url('/assets/uswds-2.5.0/img/angle-arrow-down-primary.svg'),linear-gradient(transparent,transparent);
    background-position: right 16px bottom 11px;
  }
  */
    .usa-nav__secondary {
      margin-top: 0;
    }
  
    .font-heading-xl {
      font-size: 0.8rem;
    }
  
    .font-heading-3xl {
      font-size: 1.5rem;
    }
  
    .mobile-font-alt-md {
      font-size: 0.85rem;
    }
  
    .font-alt-xl {
      font-size: 1.5rem;
    }
  
    .font-sans-sm {
      font-size: 0.8rem;
    }
  
    .font-sans-md {
      /* 14px */
      font-size: 0.875rem;
    }
  
    .mobile-list-style-none {
      list-style: none;
    }
  
    .card--hero .card__title {
      font-size: 0.9rem;
    }
  
    .mobile-padding-x-neg-2 {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  
    .mobile-text-normal {
      text-transform: initial;
    }
  
    .mobile\:text-center {
      text-align: center;
    }
  
    .mobile\:width-full {
      width: 99%;
    }
  
    .mobile\:width-full {
      width: 99%;
    }
  
    .mobile\:margin-x-14px {
      margin-left: 0.875rem;
      margin-right: 0.875rem;
    }
  }
  
  /* Mobile */
  @media (max-width: 480px) {
    .mobile\:padding-top-0 {
      padding-top: 0;
    }
  
    .mobile\:padding-top-60px {
      padding-top: 3.75rem;
    }
  
    .margin-bottom-5.mobile\:margin-bottom-0,
    .mobile\:margin-bottom-0 {
      margin-bottom: 0;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint - Mobile large ≥ 480px and above */
  /*****************************************************************/
  @media all and (min-width: 480px) {
    .height-350px {
      height: 21.875rem;
    }
  
    /* .usa-banner__button::after, */
    .usa-banner__button[aria-expanded="false"],
    .usa-banner__button[aria-expanded="true"] {
      background-position: left 287px bottom 8px;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint - Tablet ≥ 640px and above */
  /*****************************************************************/
  @media all and (min-width: 640px) and (max-width: 1023px) {
    /* Partial nav  */
    .usa-nav {
      width: 50%;
      border-left: solid 3px #fff;
      -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  
    header.position-sticky {
      top: 0;
    }
  
    /* .usa-banner__button::after, */
    .usa-banner__button[aria-expanded="false"],
    .usa-banner__button[aria-expanded="true"] {
      background-position: center center;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 640px) {
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 769px) {
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint - Desktop ≥ 1024px and above */
  /*****************************************************************/
  
  @media all and (min-width: 1366px) {
    .widescreen\:grid-col-4 {
      width: 33.3333333333%;
    }
  
    .widescreen\:grid-col-8 {
      width: 66.6666666667%;
    }
  
    /* Top search bar */
    .usa-nav__secondary {
      right: 8.8rem;
    }
  
    /* Consumers/Bankers/Analysts carousel cards */
    .glider.glider--three .card {
      max-width: 385px;
    }
  
    .glider.glider--three-wide .card {
      max-width: 411px;
    }
  
    .glider.glider--three-wide .card .card__content {
      margin: 0;
    }
  
    .glider.glider--four .card,
    .glider.glider--three-narrow-2 .card {
      max-width: 281px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1365px) {
    /* Consumers/Bankers/Analysts carousel cards */
    /* To Do: rename class */
    /*
    .carousel.caorusel-three-items
    .carousel.caorusel-three-items .card { width}
    */
    .glider.glider--three .card {
      max-width: 281px;
    }
  
    .glider.glider--three-wide .card {
      max-width: 90%;
    }
  
    .glider.glider--three-wide .card .card__content {
      margin: 0;
    }
  
    .glider.glider--four .card,
    .glider.glider--three-narrow-2 .card {
      max-width: 281px;
    }
  }
  
  @media (min-width: 640px) and (max-width: 1024px) {
    /* Consumers/Bankers/Analysts carousel cards */
    /* To Do: rename class */
    /*
    .carousel.caorusel-three-items
    .carousel.caorusel-three-items .card { width}
    */
    .glider.glider--three .card {
      max-width: 281px;
    }
  
    .glider.glider--three-wide .card {
      max-width: 90%;
    }
  
    .glider.glider--three-wide .card .card__content {
      margin: 0;
    }
  
    .glider.glider--four .card,
    .glider.glider.glider--three-narrow-2 .card {
      max-width: 281px;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 1024px) {
    .tablet\:font-size-20px {
      font-size: 1.25rem;
    }
  
    .desktop\:font-alt-lg {
      /* 24px */
      font-size: 1.5rem;
    }
  
    .desktop\:margin-x-177px {
      margin-left: 11.0625rem;
      margin-right: 11.0625rem;
    }
  
    .tablet\:font-size-25px {
      /* 25px */
      font-size: 1.5625rem;
    }
  
    .tablet\:font-size-36px {
      font-size: 2.25rem;
    }
  
    .tablet\:text-center {
      text-align: center;
    }
  
    .usa-nav {
      background-color: transparent;
    }
  
    .usa-logo img {
      width: 12rem;
      max-width: none;
      transition: scale 0.25s;
    }
  
    /* Header Sticky Nav */
    header.position-sticky {
      top: 0;
    }
  
    .usa-header {
      border-bottom-width: 3px;
    }
  
    .usa-header h2 {
      font-size: 1.35rem;
      letter-spacing: 0rem;
    }
  
    .usa-logo {
      margin-top: -7rem;
      /* margin-left: 0.6rem; */
      margin-right: 0.4rem;
    }
  
    .usa-banner__inner {
      padding-left: 14px;
      border-bottom: 1px solid;
      border-image-source: linear-gradient( 90deg, #eef0f3, #eef0f3, #ffffff, #ffffff );
      padding-bottom: 4px;
      border-image-slice: 1;
    }
  
    .usa-nav__primary button,
    .usa-nav__primary button:hover,
    .usa-nav__primary button[aria-expanded="true"],
    .usa-nav__primary button[aria-expanded="true"]:hover,
    .usa-nav__primary button[aria-expanded="false"]:hover {
      background-image: none;
      text-decoration: none;
    }
  
    .usa-nav__primary
      .usa-nav__primary-item:not(#search)
      button[aria-expanded="false"] {
      background-color: transparent;
      background-image: none;
      transition: 0.25s ease;
      /* 	transition-delay: .1s; */
      /* add back in when we can fade out the submenu */
    }
  
    /* hide the default nav hover underline */
    .usa-header--basic .usa-current::after,
    .usa-header--basic .usa-nav__link:hover::after,
    .usa-header--basic [aria-expanded="true"]::after {
      background-color: transparent;
    }
  
    /* Header blue background beneath, allowing main-content to float on top */
    #main-content {
      padding: 0;
    }
  
    /* Primary Links */
    .usa-nav__primary {
      margin-top: 1.3rem;
    }
  
    
  
    .usa-nav__primary > button,
    .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 3.8rem;
      /* display: block; */
      transition: ease 0.25s;
    }
  
    .usa-nav__primary .usa-nav__primary-item .usa-nav__link {
      border: solid 3px rgba(255, 255, 255, 0);
      padding: 1.8rem 0.1rem 3rem;
      margin: 0 1.6rem 0;
      text-align: center;
    }
  
    /* Sticky nav search icon */
    .usa-nav__primary > .usa-nav__primary-item#search button img {
      width: 2rem;
    }
  
    .usa-nav__primary > .usa-nav__primary-item#search > button {
      border: none;
      background-color: transparent;
      background-image: none;
    }
  
    .usa-nav__primary > .usa-nav__primary-item#search {
      display: block;
      opacity: 0;
      transition: opacity 0.25s;
      /*transition-delay: 0.25s;*/
    }
  
    #search .usa-accordion__button span {
      display: block;
      text-indent: -9999px;
      background-repeat: no-repeat;
      position: relative;
      height: 30px;
    }
  
    #search .usa-accordion__button[aria-expanded="false"] span {
      background-image: url("../../images/public/icon-search.svg");
    }
  
    #search .usa-accordion__button[aria-expanded="true"] span {
      background-image: url("../../images/public/close-white-alt.svg");
    }
  
    #search .usa-megamenu.usa-nav__submenu {
      border: none;
      background-color: #003256;
      margin-left: 832px;
      margin-right: -32px;
      background-image: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  
    /* mega nav dropdown hover tab */
    .usa-nav__primary
      .usa-nav__primary-item:not(#search)
      button[aria-expanded="true"] {
      border: solid 3px #fff;
      border-bottom: none;
      background-color: #003256;
      /*   background-image: url('/assets/images/meganav-bg.jpg'); */
      z-index: 900;
      background-position: top 0 left -240px;
      background-size: initial;
    }
  
    .usa-nav__submenu-list {
      margin-left: 1.5rem;
    }
  
    .usa-nav__submenu-list > li a {
      line-height: 0.5rem;
    }
  
    .usa-header--basic.usa-header--megamenu .usa-nav__inner {
      margin-top: 0;
    }
  
    /* Horizontally center the mege menu expanded area */
    .usa-megamenu.usa-nav__submenu {
      left: 0;
      border: solid 3px #fff;
      margin-top: -3px;
      margin-left: -165px;
      margin-right: 188px;
      /*100px*/
      padding: 1.6rem 1.8rem;
      background-color: #0c2946;
      transition: 0.5s ease;
      transition-delay: 0.25s;
      -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8);
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8);
      background-image: url("../../images/public/meganav-bg.jpg");
      background-position: top center;
      /*   background-position: left 0 top -93px; */
    }
  
    #search .usa-megamenu.usa-nav__submenu {
      margin-top: -11px;
    }
  
    .usa-megamenu.usa-nav__submenu::after,
    .usa-megamenu.usa-nav__submenu::before {
      background-color: transparent;
      display: none;
    }
  
    /* Intro and links in columns */
    .usa-nav__submenu article {
      padding-right: 1rem;
    }
  
    .usa-nav__submenu article h2 {
      font-weight: 500;
    }
  
    .usa-nav__submenu article p {
      color: #fff;
    }
  
    .usa-nav__submenu article ul > li {
      list-style: none;
    }
  
    .usa-nav__submenu article ul > li p a,
    .usa-nav__submenu article ul > li a {
      font-size: 0.9rem;
      letter-spacing: 0.25rem;
      font-weight: 500;
    }
  
    /* Search */
    .usa-nav__secondary {
      top: -4.8rem;
      /* right: 7rem; */
      position: absolute;
      width: 50%;
      height: 78px;
      float: right;
      transition: opacity 0.25s ease;
      transition-delay: 0.25s;
    }
  
    .usa-nav__secondary .usa-help .usa-form,
    .usa-nav__secondary .usa-help .usa-link,
    .usa-nav__secondary .usa-link {
      display: none;
    }
  
    .usa-search {
      margin-left: 0;
    }
  
    /* Secondary Nav - Search Bar top right */
    .usa-nav__secondary [role="search"] {
      max-width: 20rem;
    }
  
    .usa-nav__secondary .usa-search input[type="search"] {
      border: solid 1px #dedede;
      height: 2rem;
      margin-right: -2px;
      padding-left: 0.9rem;
      letter-spacing: 0.06rem;
      width: 22rem;
    }
  
    /* Sticky nav search dropdown */
    #search .usa-search [role="search"] {
      max-width: 100%;
    }
  
    #search .usa-header [role="search"] {
      max-width: none;
    }
  
    #search .usa-search {
      margin-left: 0;
    }
  
    #search .usa-search input[type="search"] {
      width: 24rem;
      padding-right: 47px;
    }
  
    /* Google Autocomplete dropdown */
    table.gssb_c {
      padding-right: 20px;
    }
  
    #search .usa-search p {
      text-align: right;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
    }
  
    #search .usa-help button {
      margin-left: auto;
      margin-right: auto;
      border-radius: 3px;
    }
  
    .usa-nav__secondary .usa-search .usa-button {
      background-color: #09314d;
      margin-top: 0;
      height: 2rem;
    }
  
    .usa-nav__secondary .usa-search .usa-button:hover {
      background-color: #20509e;
    }
  
    #search .usa-search button[type="submit"] {
      height: 2rem;
      width: auto;
      background-image: url("../../images/public/icon-search-blue.svg");
      /* background-size: 1.375rem; */
      margin-top: 0px;
      margin-left: -2rem;
      background-color: transparent;
      background-position: right 0.5rem top 0.6rem;
    }
  
    #search .usa-help label {
      font-size: 1.5rem;
      padding-top: 20px;
      border-top: solid 1px rgba(255, 255, 255, 0.3);
    }
  
    .half-pixel-border-white {
      -moz-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5);
      -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5);
      box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5);
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 1024px) and (max-width: 1279px) {
    .usa-nav__primary > button,
    .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 1rem;
    }
  
    .usa-footer__primary-section h3,
    .usa-footer__primary-section label {
      font-size: 1.2rem;
    }
  
    .usa-megamenu.usa-nav__submenu {
      margin-left: -165px;
      margin-right: 74px;
    }
  
    .usa-nav__secondary {
      right: 1.8rem;
    }
  
    /* iPad landscape fix */
    #search .usa-megamenu.usa-nav__submenu {
      margin-left: 56%;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  - iPad Air 3 landscape*/
  /*****************************************************************/
  @media all and (min-width: 1111px) {
    .tablet-margin-x-16rem {
      margin-left: 16rem;
      margin-right: 16rem;
    }
  
    .usa-button span {
      display: inline;
      margin-left: 0.4rem;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 1111px) and (max-width: 1112px) {
    .usa-nav__secondary {
      right: -2rem;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 1280px) and (max-width: 1365px) {
    .usa-nav__primary > button,
    .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 3.3rem;
    }
  
    .usa-nav__secondary {
      right: 8.8rem;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint  */
  /*****************************************************************/
  @media all and (min-width: 1367px) {
    /* this adds the blue bar beneath the hero/image/mosaic */
    body.usa {
      background: linear-gradient(to bottom, #003256 228px, #f9f7f3 228px);
    }
  
    body.usa.alert-active {
      background: linear-gradient(to bottom, #003256 284px, #f9f7f3 284px);
    }
  
    body.usa.sticky-active {
      background: linear-gradient(to bottom, #f9f7f3 228px, #f9f7f3 228px);
    }
  
    .usa-megamenu.usa-nav__submenu {
      margin-right: 160px;
    }
  
    .sticky-active .usa-megamenu.usa-nav__submenu {
      margin-left: -72px;
    }
  
    .sticky-active .usa-nav__primary .usa-nav__link {
      margin: -1rem 2.5rem 0;
    }
  
    .usa-nav__secondary {
      right: 7rem;
    }
  }
  
  /*****************************************************************/
  /*****************************************************************/
  /*****************************************************************/
  /* Breakpoint - Internet Explorer */
  /*****************************************************************/
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sticky-active header.position-sticky {
      position: fixed;
      left: 0;
      right: 0;
    }
  
    #search .usa-search button[type="submit"] {
      z-index: 100;
    }
  
    .usa-header--basic .usa-navbar {
      width: auto;
    }
  
    #search .usa-megamenu.usa-nav__submenu {
      margin-right: -1px;
    }
  
    .usa-megamenu.usa-nav__submenu {
      margin-right: 40px;
    }
  
    .sticky-active .usa-megamenu.usa-nav__submenu {
      margin-left: -70px;
    }
  
    .usa-nav__primary > button,
    .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 3.3rem;
    }
  
    .sticky-active .usa-nav__primary > button,
    .sticky-active .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 2.4rem;
    }
  
    .usa-nav__secondary {
      right: -2.2rem;
    }
  
    #search .usa-megamenu.usa-nav__submenu {
      margin-left: 800px;
      margin-right: -32px;
    }
  
    #search .usa-search input[type="search"] {
      width: 100%;
    }
  
    .share a,
    .share a:hover {
      overflow: visible;
      background: transparent;
    }
  }
  
  @media all and (min-width: 1367px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    #search .usa-search [role="search"] {
      padding-left: 10px;
    }
  
    #search .usa-search p {
      padding-right: 10px;
    }
  }
  
  /* End Internet Explorer */
  /*****************************************************************/
  /*****************************************************************/
  
  /*
    Utilities
    NOTE: this needs to be last in the file for utility classes to supercede other classes
  */
  
  /* Bring back the scrollbar by default for chrome */
  .custom-scrollbar ::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 4px;
    background-color: #ededed;
    width: 8px;
  }
  
  .custom-scrollbar ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #575656;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  
  .absolute-center {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  @media (min-width: 1024px) {
    .desktop\:position-sticky {
      -webkit-position: sticky;
      position: sticky;
    }
  }
  
  .flex-justify-between {
    justify-content: space-between;
  }
  
  .disable-scrollbars *::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    /* Chrome/Safari/Webkit */
  }
  
  .disable-scrollbars * {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
  }
  
  .no-mouse-events {
    pointer-events: none;
  }
  
  .text-prussian-blue,
  .text-prussian-blue:visited,
  .usa-form a:visited {
    color: #003256;
  }
  @media (min-width: 640px) {
    .tablet\:text-white {
      color: #ffffff;
    }
  }
  
  a.text-prussian-blue:hover,
  a.text-prussian-blue:visited:hover {
    color: #3163b6;
  }
  
  .text-base,
  .text-base:visited {
    color: #444343;
  }
  
  .text-yellow-metal {
    color: #736539;
  }
  
  .text-gold {
    color: #b7ac83;
  }
  
  .text-gray {
    color: #939393;
  }
  
  a.text-white,
  a.text-white:visited {
    color: #ffffff;
  }
  
  a.text-white:hover,
  a.text-white:visited:hover {
    color: #b7ac83; /* was a48923 - TH */
  }
  
  .text-weight-600 {
    font-weight: 600;
  }
  
  .text-spacing-1-71px {
    /* 1.71 px*/
    letter-spacing: 1.71px;
  }
  
  .text-lh-1 {
    line-height: 1.8rem;
  }
  
  .fill-gold {
    fill: #b7ac83;
  }
  
  .fill-blue {
    fill: #013256;
  }
  
  .bg-gold-gradient {
    background-image: linear-gradient(to right, white, #f7f7f3, white);
  }
  
  .bg-blue-gradient {
    background-image: url("../../images/public/fdic-news-multimedia-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bg-prussian-blue {
    background-color: #003256;
  }
  
  .bg-blue {
    background-color: #003256;
  }
  @media (min-width: 640px) {
    .tablet\:bg-blue {
      background-color: #003256;
    }
  }
  
  .bg-dark-blue {
    background-color: #162237;
  }
  
  .bg-yellow {
    background-color: #f9f8f5;
  }
  
  .bg-informed {
    background-image: url("../../images/public/bg-informed.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bg-eagle {
    background-image: url("../../images/public/fdic-news-calendar-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bg-podium {
    background-image: url("../../images/public/fdic-news-testimony-bg-top.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bg-flag {
    background-image: url("../../images/public/bg-flag.png");
    background-position: top left;
    background-repeat: no-repeat;
  }
  
  .bg-seal {
    background-image: url("../../images/public/seal.png");
    background-repeat: no-repeat;
    background-position: 50% 45px;
  }
  
  .bg-supervisorytopics {
    background-image: url("../../images/public/fdic-brc-supervisorytopics-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bg-spring-wood {
    background-color: #f9f7f3;
  }
  
  .bg-gold {
    background-color: #746639;
  }
  
  .border-rounded {
    border-radius: 100%;
  }
  
  .border-white-40-percent {
    border-color: #757b86;
  }
  
  .border-gray-nurse {
    border-color: #e7e8e7;
  }
  
  .border-gold,
  .tablet\:border-gold {
    border-color: #c4bfad;
  }
  
  .border-dark-gold {
    border-color: #b7ac83;
  }
  
  .outline-shadow-gold {
    outline-color: #c4bfad;
  }
  
  .width-fit-content {
    width: fit-content;
  }
  
  .margin-x-16px {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .margin-x-177px {
    margin-left: 11.0625rem;
    margin-right: 11.0625rem;
  }
  
  .margin-bottom-2 {
    /* 8px */
    margin-bottom: 0.5rem;
  }
  
  .margin-bottom-4 {
    /* 27px */
    margin-bottom: 1.6875rem;
  }
  
  /* .margin-bottom-4 {
    margin-bottom: 1rem;
  } */
  
  .margin-bottom-5 {
    /* 43px */
    margin-bottom: 2.6875rem;
  }
  
  .text-capitalize {
    text-transform: capitalize;
  }
  
  .width-281px,
  .width-300px,
  .width-192px {
    width: 100%;
  }
  
  .width-200px {
    min-width: 12.5rem;
  }
  
  .width-336px {
    min-width: 21rem;
  }
  
  .height-142px {
    /* 142px */
    max-height: 8.875rem;
  }
  
  .height-302px {
    max-height: 18.875rem;
  }
  
  .height-200px {
    max-height: 12.5rem;
  }
  
  .height-380px {
    height: 380px;
  }
  
  .maxh-380px {
    max-height: 380px;
  }
  
  .top-68px {
    /* top: 4.25rem; */
    top: 3rem;
  }
  
  /* @media (min-width: 640px) and (max-width 1365px) {
    .top-68px {
      top: 3rem;
    }
  } */
  
  @media (min-width: 1366px) {
    .tablet\:top-94px {
      top: 5.25rem;
    }
  }
  
  .truncate {
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 4.6em;
    /* fix problem when last visible word doesn't adjoin right side  */
    /* text-align: justify;  */
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;
  }
  
  /* create the ... */
  .truncate:before {
    /* points in the end */
    content: "...";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  
  /* hide ... if we have text, which is less than or equal to max lines */
  .truncate:after {
    /* points in the end */
    content: "";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
  
  .podcast-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: repeat(2, 1fr);
    -ms-grid-rows: 230px auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 230px auto;
  }
  
  @media (max-width: 1260px) {
    .podcast-grid {
      -ms-grid-rows: 260px auto;
      grid-template-rows: 260px auto;
    }
  }
  
  .podcast-grid .podcast-image {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1;
  }
  
  .podcast-grid .podcast-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 2;
  }
  
  .podcast-grid .podcast-items {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column: 2;
  }
  
  @media (max-width: 1160px) {
    .podcast-grid {
      -ms-grid-rows: auto;
      grid-template-rows: auto;
    }
  
    .podcast-grid .podcast-items {
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: none;
    }
  }
  
  @media (max-width: 1024px) {
    .podcast-grid {
      display: block;
    }
  
    .podcast-grid .podcast-image,
    .podcast-grid .podcast-description,
    .podcast-grid .podcast-items {
      width: 100%;
    }
  }
  
  /*
   responsive utility helpers
  */
  @media (min-width: 1280px) {
    .vh1280\:display-none {
      display: none;
    }
  }
  
  /*
    DataTable
  */
  
  .dataTable--cards .dataTables-content-main {
    /* display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(281px, 1fr));
  }
  
  .dataTable--cards .dataTables-content-main > * {
    margin: 0 auto;
  }
  
  .dataTable--cards .dataTables-content-main .width-281px {
    /* this is breaking grid wrapping */
    /* max-width: 100% !important; */
  }
  
  /* .dataTable--cards .dataTables-content-main>*:last-child {
    margin: 0 4.6%;
  } */
  
  /* .dataTable--cards .dataTables-content-main:after {
    content: '';
    flex-grow: 1;
  } */
  
  .dataTable--cards {
    width: 100%;
    table-layout: fixed;
  }
  
  .dataTable--cards tbody {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    width: 100%;
    table-layout: fixed;
  }
  
  .dataTable--cards tbody tr {
    display: -ms-flexbox;
    display: flex;
  }
  
  .dataTable--cards tbody td {
    display: -ms-flexbox;
    display: flex;
  }
  
  .data-table--header {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(248, 248, 245, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    /* 7px 20px */
    padding: 0.4375rem 1.25rem;
    width: 100%;
    max-width: 1324px;
  }
  
  /* IE sticky styles*/
  .data-table--header.js-is-sticky {
    margin-top: 84px;
  }
  
  select.border,
  .data-table--header input,
  .data-table--header select {
    border: 1px solid #bebdbd;
  }
  
  .dataTables_info {
    font-family: "Merriweather Web", Georgia, Cambria, Times New Roman, Times,
      serif;
    font-size: 0.875rem;
    font-style: italic;
    color: #746639;
    min-width: 210px;
  }
  
  .data-table--content {
    /* 32px */
    margin-top: 2rem;
    padding: 0 3rem;
  }
  
  /* Latest News Include fix to allow to use in header, latest news slider, and archive tile */
  .data-table--content tr td .grid-col-3 {
    display: flex;
    width: 100%;
  }
  
  .data-table--footer {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    /* 16px 20px 32px */
    margin: 1rem 1.25rem 2rem;
  }
  
  .dataTables_paginate {
  }
  
  a.paginate_button,
  .paginate_button,
  .paginate_button:visited {
    font-family: "Merriweather Web", Georgia, Cambria, Times New Roman, Times,
      serif;
    font-size: 1rem;
    font-weight: 100;
    color: #746639;
    text-decoration: none;
    padding: 0 5px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .paginate_button:hover {
    text-decoration: underline;
  }
  
  .paginate_button.first {
    background-image: url("../../images/public/icon-first.svg");
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 35px;
  }
  
  .paginate_button.last {
    background-image: url("../../images/public/icon-last.svg");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 35px;
  }
  
  .paginate_button.current,
  .paginate_button.current:hover {
    color: #fff;
    background-color: #7f7044;
    text-decoration: none;
  }
  
  @media (min-width: 1024px) {
    /* IE sticky styles */
    .data-table--header.js-is-sticky {
      margin-top: 80px;
      width: calc(100% - 66px);
    }
  }
  
  @media (max-width: 1024px) {
    .data-table--header {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: minmax(0, 1fr);
      -ms-grid-rows: minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 1fr);
      background: none;
      border: none;
      /* 7px 20px */
      padding: 0;
      background-color: #ffffff;
      overflow: hidden;
      justify-content: center;
    }
  
    /* IE sticky styles */
    .data-table--header.js-is-sticky {
      margin-top: 72px;
      width: calc(100% - 96px);
    }
  
    .data-table--header.usa-form .usa-input,
    .data-table--header.usa-form .usa-select {
      width: 100%;
      max-width: 100%;
    }
  
    .data-table--content {
      /* 32px */
      overflow-x: scroll;
      margin-top: 0;
      padding: 0;
    }
  
    .data-table--footer {
      display: none;
    }
  
    .dataTables_info {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      grid-column-start: 1;
      grid-column-end: none;
      text-align: center;
      border-top: 1px solid #dcdcdc;
      background: rgb(255, 255, 255);
      background: radial-gradient(
        circle,
        rgba(248, 248, 245, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* 20px 20px 10px */
      padding: 1.25rem 1.25rem 0.625rem;
    }
  
    .dataTables_paginate {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dcdcdc;
      background: rgb(255, 255, 255);
      background: radial-gradient(
        circle,
        rgba(248, 248, 245, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* 10px 20px 20px */
      padding: 0.625rem 1.25rem 1.25rem;
    }
  
    .dataTables_filter {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      grid-row-start: 3;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: none;
      margin-top: 0.9375rem;
    }
  
    .dataTables_length {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
      grid-row-start: 4;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: none;
      margin-top: 0.9375rem;
      margin-bottom: 15px;
    }
  
    .dataTables-sidebar caption {
      position: absolute;
      z-index: 201;
      display: block;
      top: 0;
      width: 100%;
    }
  
    .dataTables_info {
      margin-top: 20px;
    }
  }
  
  @media (max-width: 630px) {
    a.paginate_button {
      margin: 0;
    }
  
    /* IE sticky styles */
    .data-table--header.js-is-sticky {
      margin-top: 72px;
      width: calc(100% - 32px);
    }
  }
  
  @media (max-width: 480px) {
    a.paginate_button {
      margin: 0;
    }
  
    /* IE sticky styles */
    .data-table--header.js-is-sticky {
      margin-top: 72px;
      width: calc(100% - 32px);
    }
  }
  
  @media (min-width: 1024px) {
    .dataTable.dataTables-sidebar {
      display: -ms-grid;
      display: grid;
      /* 309px width for aside plus margin of 37 (roughly) */
      -ms-grid-columns: 341px 1fr;
      -ms-grid-rows: 50px 1fr;
      grid-template-columns: 341px minmax(0, 1fr);
      grid-template-rows: 50px minmax(0, 1fr);
    }
  
    /* .dataTables-sidebar tr {
      display: grid;
      grid-template-columns: 25% 15% 8% 10% 25% 17%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    } */
  
    .data-table--content {
      overflow: hidden;
    }
  
    .data-table--content .dataTables-sidebar {
      padding-left: 0;
      padding-right: 0;
    }
  
    .data-table--content {
      padding-left: 0;
      padding-right: 0;
    }
  
    .dataTables-content-header {
      grid-row: 1;
      grid-column: 2;
      display: table;
      table-layout: fixed;
      width: 100%;
      /* this needs to be the total of all min-widths below */
      min-width: 850px;
    }
  
    .dataTables-sidebar .dataTables-content-main {
      grid-row: 2;
      grid-column: 2;
      display: table;
      table-layout: fixed;
      width: 100%;
    }
  
    .dataTables-content-aside {
      grid-row-start: 1;
      grid-row-end: none;
      grid-column: 1;
    }
  
    .dataTables-sidebar caption {
      position: relative;
      z-index: 0;
    }
  }
  
  .dataTables-sidebar tr {
    display: table;
    width: 100%;
    min-width: 850px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(1),
  .dataTables-sidebar tr td:nth-of-type(1) {
    width: 25%;
    min-width: 201px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(2),
  .dataTables-sidebar tr td:nth-of-type(2) {
    width: 15%;
    min-width: 121px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(3),
  .dataTables-sidebar tr td:nth-of-type(3) {
    width: 8%;
    min-width: 65px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(4),
  .dataTables-sidebar tr td:nth-of-type(4) {
    width: 10%;
    min-width: 80px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(5),
  .dataTables-sidebar tr td:nth-of-type(5) {
    width: 25%;
    min-width: 201px;
  }
  
  .dataTables-sidebar tr th:nth-of-type(6),
  .dataTables-sidebar tr td:nth-of-type(6) {
    width: 17%;
    min-width: 137px;
  }
  
  /* IE 11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .dataTable.dataTables-sidebar {
      table-layout: fixed;
    }
  
    .dataTables-content-aside {
      display: -ms-inline-flexbox;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      -ms-grid-column: 1;
    }
  
    .dataTables-content-header {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      display: table;
      table-layout: auto;
      width: 100%;
    }
  
    .dataTables-sidebar .dataTables-content-main {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
      display: table;
      table-layout: auto;
      width: 100%;
    }
  }
  
  .dataTables-sidebar > .dataTables-content-main tr {
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: #c5c5c5;
  }
  
  .dataTables-sidebar td,
  .dataTables-sidebar td a {
    font-weight: 400;
    font-size: 0.8125rem;
    font-family: "Open Sans", "Source Sans Pro Web", "Helvetica Neue", Helvetica,
      Roboto, Arial, sans-serif;
    color: #444343;
    line-height: 1.6rem;
  }
  
  .dataTables-sidebar td {
    padding-right: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.8125rem;
    padding-bottom: 0.5rem;
    border-right: 1px solid;
    border-color: #c5c5c5;
  }
  
  .dataTables-sidebar .dataTables-content-main tr:nth-child(even) {
    /* background: rgba(237, 234, 224, 0.03); */
    background-color: #f9f8f5;
  }
  
  .dataTables-sidebar .dataTables-content-main tr:nth-child(odd) {
    background-color: #fff;
  }
  
  .sorting p:after {
    content: " ";
    color: white;
    display: inline-block;
    width: 25px;
    height: 10px;
    background: url("../../images/public/icon-sort.png") no-repeat center;
  }
  
  .sorting_asc p:after {
    content: " ";
    color: white;
    display: inline-block;
    width: 25px;
    height: 10px;
    background: url("../../images/public/icon-sort-asc.png") no-repeat center;
  }
  
  .sorting_desc p:after {
    content: " ";
    color: white;
    display: inline-block;
    width: 25px;
    height: 10px;
    background: url("../../images/public/icon-sort-desc.png") no-repeat center;
  }
  
  .dataTables-content-aside {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-row-start: 1;
    grid-row-end: none;
    grid-column-start: 1;
    grid-column-end: 1;
  }
  
  /**************************\
    START Micromodal Styles
  \**************************/
  
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.72);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999998;
  }
  
  .modal__container {
    margin-top: 10px;
    background-color: #fff;
    max-width: 1080px;
    height: auto;
    border-radius: 0px;
    box-sizing: border-box;
    z-index: 999999999;
    border: solid 5px #013256;
  }
  
  .modal__close {
    background: none;
    cursor: pointer;
    border: 0;
  }
  
  i.fas.fa-times.text-base-dark {
    font-size: 36px;
  }
  
  .modal__title {
    line-height: 2.5625rem;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  .modal__image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding-left: 14px;
    padding-right: 14px;
  }
  
  .modal__content {
    line-height: 1.6875rem;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0rem;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  /* 10.6 displays with a vertical resolution of 768 */
  @media (max-height: 768px) {
    .modal__image {
      max-height: 240px;
    }
  }
  
  /* Tablet Landscape */
  @media (min-width: 640px) {
    .modal__container {
      max-width: 730px;
    }
  }
  
  /* Tablet Portrait */
  @media (min-width: 480px) {
  }
  
  /* Mobile */
  @media (max-width: 480px) {
    .modal__container {
      margin-left: 15px;
      margin-right: 15px;
    }
  
    .modal__title {
      line-height: 1.15rem;
      margin-top: 33px;
      margin-right: 19px;
      margin-left: 21px;
    }
  
    .modal__image {
      width: auto;
      padding-left: 14px;
      padding-right: 14px;
    }
  
    .modal__content {
      line-height: 1.3125rem;
      margin-left: 14px;
      margin-right: 14px;
    }
  }
  
  /*
   * hack for IE11 to get it to work because
   * it doesn't like dipslay:flex;
   *
   * */
  @media all and (-ms-high-contrast: none) {
    .modal__overlay {
      position: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.72);
      display: block;
      justify-content: center;
      align-items: center;
      z-index: 999999998;
    }
  
    .modal__container {
      position: relative;
      top: 0;
      max-width: 1080px;
      height: auto;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  /*
   * hack for IE11 and 10.6" displays
   * this will force the height of the image
   * and bring the full modal into the screen
   *
   * */
  @media (max-height: 768px) and (-ms-high-contrast: none) {
    .modal__image {
      max-height: 340px;
    }
  }
  
  /****************************\
    Micromodal Animation Sytle
  \****************************/
  @keyframes mmfadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes mmfadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  @keyframes mmslideIn {
    from {
      transform: translateY(15%);
    }
  
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes mmslideOut {
    from {
      transform: translateY(0);
    }
  
    to {
      transform: translateY(-10%);
    }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  
  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }
  
  /****************************\
    END Micromodal Styles
  \****************************/
  .fdic-table {
    line-height: 1.5;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1.25rem 0;
  }
  
  .fdic-table th,
  .fdic-table td {
    border-width: 1px;
    border-color: #c5c5c5;
    border-style: solid;
    padding: 1rem;
  }
  
  .fdic-table-alt th,
  .fdic-table-alt td {
    border-width: 1px;
    border-color: #c5c5c5;
    border-style: solid;
    padding: 1rem 0.5rem;
  }
  .fdic-table .border-left-0 {
    border-left: 0 solid;
  }
  .fdic-table .border-right-0 {
    border-right: 0 solid;
  }
  
  /****************************\
    Link jump fix for header 
  \****************************/
  /* for links that point to a spot inside a page (link.url/#inside_place), they jump to 
     the top of the browser. But our header being fixed in place, will obscure the link.
     We need to move the link below the header. */
  a[name]:not([href])::before { /* targets anchors with a name attribute and places before the element */
    /* targets anchors with a name attribute and places before the element */
    display: block; /* make a block */
    content: " "; /* with nothing in it */
    margin-top: -88px; /* make the top of it -88 pixels high (about the height of the nav bar) */
    height: 88px; /* then make the block about the same height as the nav bar */
    visibility: hidden; /* hide it, but retain the space it's using */
    pointer-events: none; /* hide it from the mouse */
  }
  /****************************\
    text fix for mosaic 
  \****************************/
  /* titles and text on the hero mosaic are white, which can't be seen on a light image.
     This adds text shadow to the text so it can be seen. */
  /*.card.card--hero .card__title,
  .card.card--hero .card__description {
    text-shadow: 1px 1px 3px #000000;
  }*/
  
  /****************************\
     new video size
  \****************************/
  /* some videos just need to be a different size */
  .video-container-half {
    overflow: hidden;
    position: relative;
    width: 50%;
  }
  
  .video-container-half::after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
  
  .video-container-half iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /****************************\
     new graphic size
  \****************************/
  /* some graphics just need to be a different size */
  @media all and (min-width: 1024px) {
    .desktop\:width-500px {
      width: 500px;
    }
    .desktop\:width-600px {
      width: 600px;
    }
  }
  
  /****************************\
     help page images
  \****************************/
  /* sometimes its just easier to find your own path in life. */
  .helpflex {
    display: flex; 
    flex-direction: row; 
    justify-content: space-around; 
    align-items: center; 
  }
  @media all and (max-width: 1024px) {
    .helpflex {
      flex-direction: column;
    }
  }
  
  /****************************\
     failed bank spacing
  \****************************/
  /* to make the template easier to add to, making a div to 
     surround paragraphs instead of making a paragraph with double
     breaks in it because that defeats the concept */
  .failed-bank div.padding-2.padding-top-0.tablet\:padding-top-2.margin-top-0.tablet\:margin-top-2 > p:first-child { /* this will only target pages with the .failed-bank class */
    margin-top: 0;
  }
  
  .template-failed-bank .rcvrtable {
      padding-bottom: 5px; 
    }
  .template-failed-bank .rcvrtable tr > td:first-child {
      vertical-align: top;
      text-align: left;
    }
  .template-failed-bank .rcvrtable tr > td:last-child {
      vertical-align: top;
      text-align: left;
      padding-left: 1em;
    }
  .template-failed-bank .rcvrtable tr > td > p {
      margin-top: 0;
    }
  
  /****************************\
     podcast image sizing
  \****************************/
  /* on the news page we have 3 podcast graphics from 
     three sources. We just want them to be the same 
     relative height. */
  .podcast-description .display-flex a > img {
    height: 44px;
  }
  
}
.sticky-active .usa-header {
  border-bottom: solid 3px #fff;
  box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
}

.sticky-active .usa-header {
  border-bottom: solid 6px #fff;
  box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 7px 0 #b7ac83, 0 2px 6px rgba(0, 0, 0, 0.2);
}

.sticky-active .usa-header--basic .usa-nav__primary {
  margin-top: 2.7rem;
}

.sticky-active .usa-nav__primary > button,
.sticky-active .usa-nav__primary > .usa-nav__primary-item {
  margin: 0 2.9rem;
  transition-delay: 0.25s;
  transition: 0.25s;
}

.sticky-active .usa-megamenu.usa-nav__submenu {
  margin-left: -114px;
  margin-right: 14px;
}

.sticky-active .usa-nav__secondary {
  opacity: 0;
  transition: opacity 0.25s;
  right: -1.8rem;
  /* transition-delay: 0.25s; */
}

.sticky-active .usa-header--basic.usa-header--megamenu .usa-nav__inner {
  margin-top: 0;
}

.sticky-active .usa-logo {
  margin-top: 1.2rem;
  margin-left: 1rem;
}

.sticky-active .usa-logo img {
  width: 6.4rem;
  transition: scale 0.25s;
}

.sticky-active .usa-nav {
  height: 5.1rem;
}

.sticky-active .usa-navbar {
  height: 4.9rem;
}

.sticky-active .usa-nav__primary .usa-nav__link {
  &:not(.resource-filter) {
    padding: 1.2rem 0 2rem;
    margin: -1rem 2.2rem 0;
    width: 80%;
  }
}

.sticky-active .usa-nav__primary > .usa-nav__primary-item#search {
  opacity: 1;
  margin-top: -6px;
  margin-right: 15px;
  margin-left: 40px;
  transition: opacity 0.25s;
  /* transition-delay: 0.25s; */
}

.sticky-active .usa-nav__primary > button,
    .sticky-active .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 0.6rem;
    }

    .sticky-active .usa-megamenu.usa-nav__submenu {
      margin-left: -66px;
      margin-right: 74px;
    }


    .sticky-active .usa-nav__primary > button,
    .sticky-active .usa-nav__primary > .usa-nav__primary-item {
      margin: 0 2.4rem;
    }