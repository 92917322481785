.game-page {
  .game-content {
    table {
      th {
        white-space: nowrap
      }
    }
  }
  .no-login-notice {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .no-login-notice-body {
    margin-top: 0px;
    margin-bottom: 15px;
    line-height: normal;
  }
  .game-image-container {
    text-align: center;
    max-width: 540px;
    a {
      text-decoration: none;
    }
    .game-image {
      max-width: 100%;
      max-height: 100%;
      @media (max-width: $mobile-lg) {
        max-width: 100%;
      }
    }
    .play-game-banner-button {
      width: 100%;
      margin-top: -6px;
      padding: 5px;
      vertical-align: middle;
      color: white;
      background-color: $fdic-dark-tan;
      transition: all .3s;
      &:hover {
        background-color: $fdic-gold;
      }
      span {
        text-decoration: none;
        margin: 0px;
        font-weight: bold;
        font-family: $general-font-family;
        font-size: 14pt;
        color: white;
      }
    }
  }
  .table {
    margin-left: 0px;
    tr {
      vertical-align: top;
    }
    td {
      min-width: 7em;
      padding-left: 1em;
    }
  }
}
.game-modal-close-button,
.game-modal-close-button-es {
  margin-right: 1rem;
  margin-top: .5rem;
  @media (max-width: $mobile-lg-horizontal) {
    margin-right: 0px;
    margin-top: 15%;
  }
}
.game-modal-close-button::after,
.game-modal-close-button-es::after {
  content: "Close";
  position: absolute;
  color: #fff;
  top: 2rem;
  right: -0.2rem;
}
.game-modal-close-button-es::after {
  content: "Cerrar";
  right: -0.5rem;
}
.need-cert {
  display: flex;
  justify-content: space-between;
}
.acc-tabs{
  margin-bottom: 2rem;
}
.game-graph{
  margin-bottom: 1rem;
}
.txt-btn-container {
  display: flex;
}
.txt-btn-container .text-left {
  margin-top: 1rem;
  width: 100%;
}
.content-txt-block {
  width: 72%;
}
.login-alt-txt{
  font-size: 1rem;
  width: 85%;
  margin: 1rem auto 1rem auto;
}
.game-modal {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  label {
    text-align: left;
    padding-left: 1em;
  }
  .game-modal-close-button {
    background-color: white;
    border-radius: 50%;
  }
  div {
    height: 100%;
    text-align: center;
    .game-frame {
      overflow: hidden;
      height: 100%;
      width: 100%;
      display: block
    }
    &.text-align-left {
      text-align: left;
    }
    &.text-align-right {
      text-align: right;
    }
  }
  &.login {
    height: auto;
  }
  .omb-information {
    margin-left: 75%;
    text-align: right;
    height: auto;
    padding-right: 2.5rem;
  }
  @media (max-width: $mobile-lg-horizontal) {
    background-color: black;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    &.survey {
      background-color: white;
    }
  }
}
.game-login {
  .registration-wrapper {
    width: 640px;
    background-color: $fdic-light-grey;
  }
  h2{
    font-size: 28px !important;
  }
  .reg-form-btn {
    flex-direction: column !important;
  }
  button#login {
    margin: 0 auto;
  }
}