.skipheader {
  position: absolute;
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.usa-banner__header {
  min-height: 60px;
}

.usa-banner__content {
  padding-top: 0;
  margin-top: -0.7rem;
  padding-bottom: 3.7rem;
}

.usa-header__megamenu {
  margin-bottom: 1.6875rem;
}

.usa-search .usa-button {
  background-repeat: no-repeat;
  background-position: center;
}
