.usa-button {
  font-family: $general-font-family;
  font-size: 1rem;
  &.send-email-button {
    &:disabled {
      background-color: lightgray;
    }
  }
}

.usa-button--loader {
  line-height: 1.3em;
}

.usa-button--loader .spinning {
  position: relative;
  margin-right: 7px;
  top: 4px;
  animation: spin 1s infinite linear;
  font-size: 1.2em;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.fdic-button {
  padding: .5rem 2rem;
  min-height: 35px;
  min-width: 180px;
  background-color: $fdic-primary;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: .25rem; 
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background-color: $fdic-alternate-primary;
    color: white;
    text-decoration: none;
  }
  &.tan-button {
    background-color: $fdic-dark-tan;
    &:hover {
      background-color: $fdic-gold;
      color: white;
      text-decoration: none;
    }
  }
  &.tall-button {
    padding-top: .85em;
    padding-bottom: .85em;
  }
  &.normal-font-size {
    font-size: 1em;
  }
  &:disabled {
    background-color: #c9c9c9;
    color: white;
    cursor: default;
  }
  @media only screen and (max-width: 1300px) {
    min-width: auto;
  }
}

.fdic-button--link {
  font-weight: bold;
  font-family: 'Open Sans';
  color: #003256;
  -webkit-font-smoothing: antialiased;
  &:hover {
    color: #20509E;
    -webkit-font-smoothing: antialiased;
  }
}