.tools {
  table.layout-table {
    tbody {
      tr {
        td {
          padding-left: 10px;
          padding-right: 10px;
          border: none !important;
          @media (max-width: $mobile-lg) {
            padding-left: 2px;
            padding-right: 1px;
          }
          p {
            margin: 0;
          }
        }
      }
    }
  }
  input[type="checkbox"] {
    transform: scale(1.3);
    padding: 10px;
    margin-left: 15px;
    width: auto;
  }
  textarea {
    width: 100%;
  }
  table.usa-table {
    background-color: $fdic-primary;
    border-collapse: unset;
    width: 100%;
    overflow-x: scroll;
    box-shadow: 0 0 8px #0000002b;
    font-size: 16px;
    font-family: $general-font-family;
    &.no-border {
      td {
        border: none;
      }
    }
    .border-top {
      border-top: 1px solid $fdic-alternate-dark-grey;
    }
    li {
      font-size: 16px;
      font-family: $general-font-family;
    }
    @media print {
      background-color: $fdic-light-grey;
      border: 5px solid $fdic-light-grey;
    }
    td {
      padding-left: 10px;
      padding-right: 10px;
      border-top: none;
      border-bottom: none;
      @media (max-width: $mobile-lg) {
        padding-left: 2px;
        padding-right: 1px;
      }
    }
    thead {
      tr {
        th {
          font-size: $table-header-font-size;
          font-weight: bold;
          background-color: $fdic-primary;
          color: white;
          border: 1px solid $fdic-alternate-dark-grey;
          @media print {
            color: black;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $fdic-light-grey;
            padding: 0;
            p {
              margin: 5px;
            }
          }
        }
        td {
          background-color: transparent;
        }
      }
    }
    tbody {
      tr {
        .item-column {
          padding-right: 8px;
        }
        th {
          border-left: 1px solid $fdic-alternate-dark-grey;
        }
        td,
        th {
          background-color: $fdic-light-grey;
          border-bottom: 1px solid $fdic-alternate-dark-grey;
          border-right: 1px solid $fdic-alternate-dark-grey;
          border-top: 0px;
          h4 {
            color: $fdic-cyan;
          }
          @media print {
            padding: 0;
            p {
              margin: 5px;
            }
          }
        }
        th {
          &:first-of-type {
            input,
            textarea {
              width: 100%;
              @media (max-width: $desktop-working) {
                margin-bottom: 5px;
              }
            }
          }
        }
        td {
          border-left: 0px;
          .money-input {
            &::before {
              content: "$";
              font-weight: bold;
            }
            input {
              width: 90%;
            }
          }

          .percentage-input::before {
            content: "%";
            font-weight: bold;
          }
          .money-input,
          .percentage-input {
            input.leadingicon-input,
            textarea.leadingicon-input {
              width: 86%;
            }
          }
          input,
          textarea {
            width: 100%;
            @media (max-width: $desktop-working) {
              margin-bottom: 5px;
            }
          }
          input[type="checkbox"],
          input[type="radio"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 10px;
            width: auto;
          }
        }
        @media print {
          td,
          th {
            border-bottom: 1px solid $fdic-light-grey;
          }
        }

        .col-1 {
          width: 8.34% !important;
          input,
          textarea,
          :not(input[type="radio"]) {
            width: 100%;
          }
          input[type="checkbox"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 15px;
            width: auto;
          }
        }
        .col-2 {
          width: 16.67% !important;
          input[type="checkbox"],
          input[type="radio"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 15px;
            width: auto;
          }
          label {
            input {
              width: 50%;
            }
          }
        }
        .col-3 {
          width: 25% !important;
          input[type="checkbox"],
          input[type="radio"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 15px;
            width: auto;
          }
        }
        .col-4 {
          width: 33.33% !important;
          input[type="checkbox"],
          input[type="radio"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 15px;
            width: auto;
          }
        }
        .col-5 {
          width: 41.67% !important;
          input[type="checkbox"],
          input[type="radio"] {
            transform: scale(1.3);
            padding: 10px;
            margin-left: 8px;
            width: auto;
          }
        }
        .col-6 {
          width: 50% !important;
        }
      }
      textarea {
        resize: vertical;
      }
    }
    @media print {
      width: 95%;
    }
    &.strategy-table {
      tbody {
        vertical-align: top;
      }
      li {
        font-size: 16px;
      }
    }
  }
  .add-row-button {
    font-size: 24px;
    background-color: $fdic-bright-blue;
    padding: 0.25em;
    padding-bottom: 0.1em;
    padding-top: 0.09em;
    border-radius: 50%;
    margin-right: 0px;
    @media print {
      display: none;
    }
    @media (max-width: $desktop-working) {
      border-radius: 5px;
    }
  }
  .remove-row-button {
    font-size: 24px;
    background-color: #c8326c;
    padding: 0.37em;
    padding-bottom: 0.17em;
    padding-top: 0em;
    padding-right: 0.38em;
    border-radius: 50%;
    margin-bottom: 5px;
    margin-right: 3px;
    @media print {
      display: none;
    }
    @media (max-width: $desktop-working) {
      border-radius: 5px;
    }
  }
  input,
  textarea {
    @media print {
      border: none;
    }
  }
  .play-game-banner {
    padding-top: 5em;
    padding-left: 3em;
    color: white;
    background-size: 100%;
    height: 200px;
    background: no-repeat;
    background-image: url("../../images/public/playGameBannerBackground.png");
    .image-wrapper {
      padding-left: 2em;
      max-height: 100%;
      display: flex;
      vertical-align: bottom;
    }
    .launch-game-button {
      background-color: $fdic-secondary;
      border-radius: 5em;
      padding-left: 2em;
      padding-right: 2em;
      &:hover {
        background-color: $fdic-secondary;
      }
    }
    @media (max-width: $desktop-lg) {
      background-image: none;
      background: $fdic-primary;
      padding: 2rem 2rem 3rem;
      height: auto;
    }
    h3 {
      color: white;
    }
  }
  ul,
  ol {
    li {
      margin-bottom: 24px;
    }
    ul,
    ol {
      li {
        margin-top: 8px;
        margin-bottom: 0px;
      }
    }
  }
  .subtotal-row {
    font-size: 18px;
    font-weight: bold;
  }
  table.summary-table {
    font-size: 20px;
    tr:last-child {
      font-weight: bold;
    }
  }
  table.fixedWidth-table {
    tr:first-child {
      th {
        width: 33%;
    }
  }
    tr:last-child {
      font-weight: bold;
    }
  }

}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.learn-parts-of-a-check {
  .container {
    position: relative;
    @media (min-width: $desktop-working) {
      width: 80%;
    }
  }

  /* Make the image responsive */
  .container img {
    width: 100%;
    height: auto;
  }
  table.summary-table {
    tr:focus {
      td,
      th {
        background: $fdic-dark-grey;
      }
    }
  }

  /* Style the button and place it in the middle of the container/image */
  .container .btn {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 0px;
    &.a {
      top: 4.5%;
      left: 3.4%;
    }
    &.b {
      top: 9.5%;
      left: 61%;
    }
    &.c {
      top: 3.6%;
      left: 86.5%;
    }
    &.d {
      top: 25%;
      left: 3.4%;
    }
    &.e {
      top: 26.5%;
      left: 67.2%;
    }
    &.f {
      top: 41.3%;
      left: 6.1%;
    }
    &.g {
      top: 54.5%;
      left: 12.4%;
    }
    &.h {
      top: 60.8%;
      left: 4.9%;
    }
    &.i {
      top: 58.5%;
      left: 55.5%;
    }
    &.j {
      top: 78%;
      left: 3.5%;
    }
    &.k {
      top: 68.7%;
      left: 38.5%;
    }
    &.l {
      top: 68.7%;
      left: 46.8%;
    }
    font-weight: bold;
    border-radius: 50%;
    color: white;
    &:hover {
      background: transparent;
    }
  }
}

.read-pay-statement {
  p {
    @media print {
      margin: 0px;
    }
  }
  .container {
    position: relative;
  }
  .container a {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 0px;
    // background-color: purple;
    &.A {
      top: 11.5%;
      left: 51.5%;
    }
    &.B {
      top: 13.5%;
      left: 78.2%;
    }
    &.C {
      top: 21.5%;
      left: 29.3%;
    }
    &.D {
      top: 25%;
      left: 51.5%;
    }
    &.E {
      top: 44%;
      left: 51.5%;
    }
    &.F {
      top: 50%;
      left: 3%;
    }
    &.G {
      top: 57%;
      left: 51.5%;
    }
    &.H {
      top: 81.7%;
      left: 51.5%;
    }
  }
  a.letter-button {
    font-weight: bold;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    width: 5%;
    background-color: $fdic-primary;
    border-radius: 50%;
    color: white;
    font-size: 1rem;
    text-decoration: none;
    &:hover {
      background-color: $fdic-primary;
    }
    &.f {
      margin-right: -2em;
    }
    &:not(.f) {
      margin-left: -2em;
    }
    @media print {
      color: black;
      font-size: 1.5rem;
    }
  }
  table.pay-statement {
    border: 1px solid black;
    page-break-inside: avoid;
    h3 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: $general-font-family;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    td {
      padding-left: 1em;
      padding-right: 1em;
    }
    td.spacer {
      height: 15px;
    }
  }
  table.summary-table {
    tr:focus {
      td {
        background: linear-gradient(180deg, #e2e2e2 0%, #c4c4c4 100%);
      }
    }
  }
}

.read-credit-card-statement {
  .container {
    position: relative;
    width: 80%;
  }

  /* Make the image responsive */
  .container img {
    width: 100%;
    height: auto;
  }

  /* Style the button and place it in the middle of the container/image */
  .container .btn {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 0px;
    &.a {
      top: 2.4%;
      left: 3.3%;
    }
    &.b {
      top: 3.2%;
      left: 48.4%;
    }
    &.c {
      top: 22.2%;
      left: 2%;
    }
    &.d {
      top: 33.1%;
      left: 48.3%;
    }
    &.e {
      top: 36.7%;
      left: 4.3%;
    }
    &.f {
      top: 48.7%;
      left: 4.2%;
    }
    &.g {
      top: 83.7%;
      left: 4.3%;
    }
    font-weight: bold;
    border-radius: 50%;
    color: white;
    &:hover {
      background: transparent;
    }
  }
  .summary-section {
    &:focus {
      background: $fdic-dark-grey;
    }
  }
}

.manageSavings .desktop\:grid-col-5,
.strategy .desktop\:grid-col-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media print {
    display: none;
  }
}

.strategy-card {
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  &.with-border {
    box-shadow: 0 0 8px #0000002b;
  }
  .image:not(.image-style-side) {
    float: left;
    padding-right: 2rem;
  }
  .image {
    padding-top: 2rem;
  }
}

.strategy-content {
  @media print {
    width: 100%;
  }
}

.strategy {
  li {
    font-family: $general-font-family;
    font-size: 16px;
  }
}

.tool-text-area {
  .label-row {
    background-color: $fdic-primary;
    color: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
  }
  textarea,
  input {
    background-color: $fdic-light-grey;
    border-color: $fdic-dark-grey;
  }
}
