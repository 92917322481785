$fdic-secondary: #C8326C;
$fdic-primary: #003256;
$fdic-alternate-primary: #20509E;
$fdic-light-primary: #20509E;
$fdic-light-blue: #bce0fd;
$fdic-bright-blue: #38b6ff;
$fdic-dark-grey: #d3d3d3;
$fdic-light-grey: #f2f2f2;
$fdic-faded-grey: #707070;
$fdic-alternate-dark-grey: #b6b6b8;
$fdic-faded-secondary: #a8226b40;
$fdic-faded-primary: #c0cfe0;
$fdic-orange: #f36946;
$fdic-red: #ba0c2f;
$fdic-light-red: #b46272;

$fdic-cyan: #00908C;
$fdic-gold: #A48923;
$fdic-light-tan: #B7AC83;
$fdic-dark-tan: #7F7141;
$fdic-alternate-tan: #656565;

$uswds-grey: #dfe1e2;

$body-text-color: #1b1b1b;

.color-primary {
  color: $fdic-primary
}

.color-white {
  color: white;
}

.color-yellow {
  color: yellow;
}

.color-cyan {
  color: $fdic-cyan !important;
}

.color-secondary {
  color: $fdic-secondary !important;
}

.color-dark-tan {
  color: $fdic-dark-tan !important;
}

.color-dark-grey {
  color: $fdic-faded-grey !important;
}

.color-alternate-tan {
  color: $fdic-alternate-tan !important;
}

.background-color-primary {
  background-color: $fdic-primary;
}

.background-color-faded-secondary {
  background-color: $fdic-faded-secondary !important;
}

.background-color-faded-primary {
  background-color: $fdic-faded-primary !important;
}

.background-color-light-grey {
  background-color: $fdic-light-grey
}

.background-color-blue {
  color: white;
  background-color: $fdic-primary
}

.background-color-orange {
  color: white;
  background-color: $fdic-orange;
}

.background-color-white {
  background-color: white !important;
  color: black;
}

.background-color-gold {
  background-color: $fdic-gold !important;
  color: white;
}