
.resources-wrapper, .game-page{
  .react-tabs__tab {
    width: 24.77%;
    text-align: center;
    vertical-align: middle;
    border: none;
    @media only screen and (max-width: $mobile-lg){
      width: 24.6% !important;
    }
    @media only screen and (max-width: $mobile-sm){
      width: 24.4% !important;
    }
    @media (min-width: $mobile-lg) and (max-width: $desktop-working) {
      width: 24.6% !important;
    }
    &:not(:first-of-type) {
      margin-left: 3px;
    }
    h3 {
      margin-top: 15px;
      margin-bottom: 15px;
      @media only screen and (max-width:$mobile-sm){
        font-size: 1rem !important;
        line-height: 18px;
      }
    }
    &:not(.react-tabs__tab--selected) {
      background-color: $fdic-primary;
      h3 {
        color: white;
      }
      &:hover {
        background-color: $fdic-alternate-primary
      }
    }
    &.react-tabs__tab--selected {
      border-top: solid lightgray thin;
      border-radius: 0px;
    }
  }
  
  .react-tabs__tab-panel {
    padding: 25px;
    padding-top: 0px;
  }
  .react-tabs__tab-list{
    border-bottom: none;
    @media only screen and (max-width:$mobile-sm){
      display: none;
    }
  }
  .react-tabs {
    border: solid lightgray thin;
    border-top: none;
  }
  
  .tool-container {
    &:not(:last-of-type) {
      border-bottom: solid lightgray thin
    }
    .pdf-icon {
      color: $fdic-secondary;
      @media only screen and (max-width: $mobile-sm){
        display: none;
      }
    }
  }
  
  .game-group-header {
    border-bottom: solid darkgray thin
  }
  /********Tab Icons End************/
  
  /********Filter Component********/
  .filter-dropdown {
    background-color: $fdic-light-grey;
    button{
      min-width: 250px;
      padding-top: 15px;
      padding-bottom: 15px;
      &[aria-expanded=true] {
        background-color: $fdic-light-grey;
      }
    }
    .filter-option-group {
      list-style: none;
      padding-left: 0px;
      width: 100%;
      li:not(:last-child){
        border-bottom: solid lightgray thin;
      }
      @media (max-width: $mobile-lg) {
        li:last-child {
          border-bottom: solid lightgray thin;
        }
      }
    }
    .filter-button-text{
      color: #656565;
      font-size: 20px;
      font-weight: bold;
      font-family: $general-font-family;
    }
    ul.filter-options {
      min-width: 250px;
      display: none;
      width: max-content;
      &.expanded {
        display: flex;
        flex-direction: row;
        position: absolute;
        background-color: white;
        border: solid lightgray thin;
        @media (max-width: $mobile-lg) {
          flex-direction: column
        }
      }
      .filter-option {
        color: #656565; 
      }
    }
  }
  .stacked-tabs{
    display: none;
    @media only screen and (max-width:$mobile-sm){
      display: block;
    }
  }
  .current-tabs{
    display: inherit;
    @media only screen and (max-width:$mobile-sm){
      display: none;
    }
  }
  
  
  /* Dropdown */
  .dropdown {
    position: relative;
    width: 100%;
  }
  .dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: $fdic-secondary;
    color: white;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-family: $header-font-family;
  }
  
  .dd-button:after {
    content: '';
    position: relative;
    top: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    z-index: 9;
    left: 15px;
    top: 15px;
  }
  .dd-input {
    display: none;
  }
  .dd-menu {
    position: relative;
    z-index: 1;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    background-color: #ffffff;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    .resources-tabs {
      height: auto;
    }
    .react-tabs__tab {
      h3 {
        height: auto;
        margin: 0px
      }
    }
  }
  .dd-input + .dd-menu {
    display: none;
  } 
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
  ul.dd-menu .react-tabs__tab {
    width: 100% !important;
    margin: 0;
    border-bottom: 1px solid #fff;
  }
}