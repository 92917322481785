
.breadcrumb {
  z-index: 10;
  position: relative;
  margin: 15px 0;

  @media (max-width: $desktop-working) {
    margin-right: 30px;
  }

  .material-icons {
    position: relative;
    font-size: 18px;
    top: 4px;
    margin-right: 5px;
  }

  a {
    font-size: $plain-text-font-size;
    color: $fdic-secondary;
    text-decoration: none;
    margin-left: 8px;
    &.last {
      color: $fdic-faded-grey;
    }
  }

  .fa-chevron-right {
    color: $fdic-faded-grey;
  }

  .fa-home {
    margin-right: 5px;
  }
}