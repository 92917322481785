.organization-details {
  .address-form {
    border: 1px solid $fdic-dark-grey;
    border-radius: 3px;
    padding-left: 15px;
    padding-top: 15px;
    select {
      width: 100%;
    }
  }
}

.edit-organization {
  tr.removed {
    td {
      background-color: $fdic-light-red
    }
  }
  tr.new {
    td {
      background-color: $fdic-light-blue
    }
  }
}