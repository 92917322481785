

.search--xlarge {
  .usa-input {
    font-size: 18px;
    height: 64px;
  }

  .usa-button {
    height: 64px;
    margin-top: 8px;
    margin-left: -5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .material-icons {
    display: none;
  }
}

.search-title {
  min-width: 500px;
  margin-bottom: 0;
}

.search--xs {
  position: relative;

  form > div {
    min-width: 100%;
  }

  .usa-input {
    font-size: 14px;
    min-width: 100%;
    height: 36px;
    padding: 10px !important;
  }

  .usa-search {
    width: 100%;
  }

  .usa-button {
    height: 36px;
    padding: 5px 10px;
  }

  .search-term {
    display: none;
  }
}

.usa-header {
  .usa-search {
    position: relative;
    top: -10px;
  }
}