.registration-wrapper {
  width: 640px;
  height: auto;
  // margin: 5rem auto;
  box-shadow: 0 20px 40px 0 rgba(38, 153, 251, 0.2);
  border: none;
  background-color: #ffffff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &.auto-width {
    width: auto;
  }
  fieldset {
    border: none;
  }
  h1,
  h2 {
    text-align: center;
  }
  label {
    display: flex;
    flex-direction: column;
  }
  textarea {
    height: 112px;
  }
  input:not([type='checkbox']):not([type='radio']),
  select {
    height: 35px;
  }
  input:not([type='checkbox']):not([type='radio']),
  textarea,
  select {
    width: 260px;
    border: solid 1px $fdic-dark-grey;
    background-color: white;
    padding: 20px;
    transition: all 0.3s;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:focus {
      outline: none;
      transition: all 0.3s;
      box-shadow: 0 0 10px rgba(38, 153, 251, 0.37);
    }
  }
  select {
    width: 100%;
    height: 42px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  input[type='radio'] {
    margin-right: 0.5em;
  }

  .reg-form-btn {
    display: flex;
    flex-direction: column;
    align-content: center;
    .usa-button {
      margin: 0 auto;
    }
    .link-btn {
      color: $fdic-primary;
      font-weight: bold;
      margin-top: 0.55rem;
      background: none;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .pwreset-form {
    text-align: center;
    padding: 2.5rem 6.5rem 2.5rem 6.5rem;
    li {
      text-align: center;
      width: 100%;
      input {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: $mobile-sm) {
    width: 22rem;
  }
}
