.editable-content {
  display: inline;
  .editable-element {
    display: inline;
    p {
      margin: 0;
      margin-bottom: 1em;
    }
    ul {
      margin-top: 0;
    }
    li {
      margin-bottom: 15px;
      li {
        margin-bottom: 0;
      }
    }

    figure.table {
      margin-left: 0px;
    }

    table {
      margin-top: 15px;
      tr {
        &:not(:last-child) {
          border-bottom: solid lightgray 1px;
        }
      }
      & th:nth-child(1) {
        vertical-align: top;
      }
    }
    td {
      p {
        margin-top: 0;
      }
      padding-left: 1em;
      min-width: 6em;
      font-size: 16px;
      &:last-child {
        max-width: 6em;
        text-align: right;
      }
    }
  }
  .edit-success {
    border: 1px solid green;
    background-color: lightgreen;
    color: green;
    width: 50%;
    padding: 0.3em 1em;
    margin: 0.2em 2em;
    border-radius: 3px;
    -webkit-animation: seconds 1s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 3s;
    animation: seconds 1s forwards;
    animation-iteration-count: 1;
    animation-delay: 5s;
  }
  .edit-failure {
    border: 1px solid red;
    background-color: lightcoral;
    color: darkred;
    width: 50%;
    padding: 0.3em 1em;
    margin: 0.2em 2em;
    border-radius: 3px;
    -webkit-animation: seconds 1s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 3s;
    animation: seconds 1s forwards;
    animation-iteration-count: 1;
    animation-delay: 5s;
  }
  @-webkit-keyframes seconds {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
      overflow: hidden;
    }
  }
  @keyframes seconds {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
      overflow: hidden;
    }
  }
}

.editable-edit {
  font-size: 16px;
}
.edit-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  margin-left: 3px;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  &.white {
    color: white;
  }
}

.accordion {
  .editable-content {
    .editable-element {
      table {
        td {
          vertical-align: top;
          max-width: 100%;
          min-width: 0%;
          &:last-child {
            text-align: left;
          }
          p:not(:first-of-type) {
            margin-top: 1em;
          }
        }
      }
    }
  }
}

.image-style-side {
  float: right;
}

.email-sent-message {
  border: 1px solid green;
  background-color: lightgreen;
  color: green;
  width: 50%;
  padding: 0.3em 1em;
  margin: 0.2em 2em;
  border-radius: 3px;
}
.game-page.category-details {
  .editable-content {
    .editable-element {
      table {
        th {
          vertical-align: middle;
        }
      }
    }
  }
}