html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  background: color('white');
  font-size: 1rem;
  line-height: 1.5;
  height: auto;
  color: $body-text-color;
}
button {
  border: none;
}
div.alert-registration {
  max-width: 100%;
  color: $fdic-red;
}
div.alert {
  max-width: 100%;
  width: 260px;
  color: $fdic-red;
  &.alert-box {
    width: 100%;
    border: solid $fdic-red 1px;
    border-radius: 2px;
    background-color: lightcoral;
    color: $fdic-red;
    padding-left: 1em;
  }
  &.info-box {
    width: 100%;
    border: solid $fdic-primary 1px;
    border-radius: 2px;
    background-color: $fdic-light-blue;
    color: black;
    padding-left: 1em;
  }
}
input.alert,
textarea.alert {
  border: 2px solid #e45459 !important;
  color: $fdic-red;
}

div.success-message {
  padding: 1em;
  border: 1px solid green;
  background-color: lightgreen;
  border-radius: 2px;
}

.usa-btn-link {
  font-weight: bold;
  font-family: 'Open Sans';
  color: #003256;
  text-decoration: underline;
  background-color: transparent;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  &:hover {
    cursor: pointer;
    color: #20509e;
    text-decoration: underline;
  }
  &:disabled {
    color: #c9c9c9;
    cursor: default;
  }
}
