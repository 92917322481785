div#extended-logo {
  display: flex;
  flex-direction: row;
}
.logo-txt-wrapper {
  margin-left: 1rem;
}
.logo-wrapper {
  width: 10rem;
}
.logo-wrapper img {
  width: 100%;
}
nav.usa-nav {
  background: #4D78A5;
}

.usa-nav__link {
  font-size: .8rem !important;
  color: #fff !important;
  font-weight: normal !important;
}
.search-info .usa-input{
  max-width: 38rem;
}
.glossary-content {
  display: flex;
  flex-direction: row;
  .cartoon{
    @media only screen and (max-width:$mobile-lg){
      display: none;
    }
  }
}
.glossary-details {
  margin: 0px;
  p {
    margin: 0px;
  }
}
.search-info ul{
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;   
}
.search-info ul li{
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.search-info ul li a {
  display: block;
  padding: .38rem;
  font-size: 1.70rem;
  font-weight: 600;
  text-decoration: none;
  color: #ccc;
}
.search-info ul li .active{
  color: $fdic-dark-tan;
  &:hover {
    color: $fdic-primary;
  }
}
.search-info .search-title{
  display: block;
  text-align: center;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 30px;
  margin-bottom: 20px;
  @media only screen and (max-width:$mobile-sm){
    text-align: left;
    min-width: auto;
  }
}
.cartoon  {
  position: relative;
  width: 35%;
  img {
    bottom: 0;
    width: 90%;
    position: absolute;
    left: 2rem;
    &.empty-display {
      top: 4rem;
    }
  }
  
}
h3.error-message {
  color: $fdic-red;
  display: block;
  padding: 1rem;
  width: 41rem;
  border: 2px solid $fdic-red;
  margin: 0;
}
footer img {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 64em){
  .usa-header--extended .usa-logo {
      max-width: 67%;
  }
}