.game-category-container{
  padding-right: 10px;
  border-left: 8px solid $fdic-gold;
  &:last-of-type {
    border: none;
    box-shadow: none;
  }
  .game-category {
    background-color: $fdic-light-grey;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    height: 280px;
    box-shadow: 0 0 8px #0000002b;
    .material-icons {
      margin-right: 8px;
      color: $fdic-light-tan;
      font-size: 8px;
      font-weight: normal;
      position: relative;
    }
    a, a:visited {
      text-decoration: none;
      color: $fdic-primary;
      &:hover {
        text-decoration: underline;
        color: $fdic-alternate-primary;
      }
    }
    a.game-link {
      font-size: 20px;
      text-decoration: underline;
    }
    h2 {
      margin-top: 0rem;
      margin-bottom: 0.25rem;
      color: $fdic-alternate-tan;
      font-weight: normal;
    }
    .game-row {
      .grid-col-1 {
        width: 4%;
      }
    }
  }
}
