.footer {
  @include u-border-top('1px', 'base-lighter');
  clear: both;
  background: color('white');
  font-size: 14px;
  color: color('base-dark');
  width: 100%;
  clear: both;
  margin: 2em auto 0;
  background-color: $fdic-primary;
  background-image: url(https://www.fdic.gov/responsive/footer/graphics/footer_seal.png);
  background-position: 80% 100%;
  background-repeat: no-repeat;
  font-size: 10px;
  line-height: 9px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;


  .title {
    padding-top: 15px;
  }

  .icf-logo-container {
    .icf-logo-text {
      @include u-border-right('1px', 'base-lighter');
      padding-right: 5px;
      margin-right: 5px;
      margin-top: 5px;
      line-height: 16px;
      padding-left: 20px;
    }
    
    img {
      width: 100%;
    }
  }

  .disclaimer {
    @include u-border('1px', 'base-lighter');
    padding: 15px;
    color: #666;
    margin-top: 30px;
    font-size: 13px;
    line-height: 14px;
  }
}

.footer-discalimer {
  padding-top: 120px;
  padding-bottom: 5px;
}

.footer img {
  width: 100%;
}
.responsive_footer-full ul {
  display: inline;
  margin: 0;
  padding: 0;
}
.responsive_footer-full ul li{
  display: inline;
  margin: 0;
  padding: 0;
}
.responsive_footer-full a {
  text-decoration: none;
  color: #fff;
  padding: 0 5px;
  border-left: 1px solid white;
}
.responsive_footer-full ul li:first-child a {
  border-left: none;
}