.game-usage-report {
  .game-row {
    position: relative;
    border-bottom: 5px solid white;
    background: $fdic-primary;
    cursor: pointer;
    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: $fdic-dark-tan;
    }

    .accordion-title {
      padding: 15px;
      font-weight: bold;
      font-size: 14pt;
      color: white;
      @media (max-width: $mobile-lg) {
        padding-right: 30px;
      }
    }
  }
}