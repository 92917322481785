.fdic-input {
  border: solid 1px;
  border-color: #bce0fd;
  height: 2.5em;
  width: 15em;
}

.usa-input {
  font-family: $general-font-family;
}

.player-admin-component {
  .usa-input {
    &:disabled {
      border: 1px solid $fdic-faded-grey;
      color: $fdic-dark-grey;
      background-color: $fdic-dark-grey;
    }
  }
}

.rbt {
  width: 100%;
}
.rbt-input {
  @extend .usa-input, .fdic-input;
  font-size: 1rem !important;
  line-height: normal !important;
  width: 100% !important;
  max-width: none;
}

.rbt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  li:hover,
  li:focus {
    background-color: lightgrey;
  }
  a {
    padding-left: 1em;
    text-decoration: none;
    color: black;
  }
}

.rbt-input-hint {
  height: 100%;
  input {
    margin-top: 0 !important;
    height: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.rbt-highlight-text {
  background: lightgrey;
}

.rbt-sr-status {
  display: none;
}

.fdic-checkbox {
  vertical-align: middle;
  align-items: center;
  display: flex;
  input {
    width: 12%;
  }
  label {
    width: 88%;
  }
}
