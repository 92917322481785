.help-center {
  .account-type {
    border-radius: 100px;
    margin-right: 5px;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    white-space: nowrap;
    &.individual {
      border: 2px solid $fdic-primary;
      color: $fdic-primary;
    }
    &.public {
      border: 2px solid $fdic-red;
      color: $fdic-red;
    }
    &.organization {
      border: 2px solid $fdic-dark-tan;
      color: $fdic-dark-tan;
    }
    &.admin {
      border: 2px solid $fdic-cyan;
      color: $fdic-cyan;
    }
    &.support {
      border: 2px solid $fdic-red;
      color: $fdic-red;
    }
    &.editor {
      border: 2px solid $fdic-bright-blue;
      color: $fdic-bright-blue;
    }
  }
  .help-topic-category {
    padding-left: 1rem;
  }
}

.help-topic-in-list {
  div.help-topic-details {
    border-bottom: 1px solid $fdic-dark-grey;
  }
}

.edit-help-topic {
  .ck-editor {
    width: 100%;
  }
}

.help-center-search {
  .condensed-topic-list .help-topic-in-list .help-topic-details {
    :not(h4) {
      display: none;
    }
  }
}

.need-more-help-topic {
  .contact-col {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10%;
    padding-right: 10%;
    &.border-right {
      border-right: 1px solid $fdic-dark-grey;
    }
  }
}
.help-center-content {
  table {
    tbody {
      td {
        vertical-align: top;
      }
    }
  }
}

.help-center-hr {
  height: 1rem;
  background-color: $fdic-dark-tan;
}