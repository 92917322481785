.navigator-container {
  text-align: left;
  color: #4f4f4f;
  font-weight: bold;
  margin-top: 2px;
  overflow-wrap: break-word;
  
  a.navigator-item {
    font-size: 18px;
    text-decoration: none;
    padding-left: 5px;
    display: inline-block;
    overflow-wrap: break-word;
    &.active {
      span {
      color: darken(#003256, 20%);
      text-decoration: underline;
      }
    }
    &:not(:last-of-type):after {
      padding-left: 5px;
      content: "|";
      // text-decoration: none !important;
    }
  }
  .ms-logo-container {
    text-align: right;
    position:relative;
    z-index: 10;
    margin-top: 18px;
    margin-bottom: -90px;
    .ms-logo {
      height: 60px;
      width: 60px;
    }
  }
  // a[title="My Account"] {
  //   @media only screen and (max-width:375px){
  //     display: block;
  //     padding: .35rem;
  //     margin: 0 auto 1rem auto;
  //     width: 50%;
  //     border: 1px solid #ddd;
  //     background: #005ea2;
  //     color: #fff !important;
  //     &::after {
  //       display: none;
  //     }
  //   }
  // }

}

.side-navigator {
  div.money-smart-logo {
    margin-bottom: 2rem;
  }
  ul {
    margin-bottom: 4rem;
    border-right: 1px solid $uswds-grey;
    border-left: 1px solid $uswds-grey;
    border-top: 1px solid $uswds-grey;
    li {
      margin-left: 5px;
      &:first-child {
        border-top: none
      }
      &:not(:first-child) {
        margin-right: 5px;
      }
    }
  }
  div.help-explanation {
    border: 1px solid $uswds-grey;
    padding-top: 1rem;
    padding-bottom: .5rem;
    padding-left: 3px;
    padding-right: 3px;
    div.compass-icon-row {
      margin-bottom: 8px
    }
    div.help-center-button-row {
      margin-top: 4px;
      margin-bottom: 12px;
    }
  }
}