.card {
  background: color('white');
  padding: 0 15px;
  border-radius: 2px;
  border: 1px solid color('base-lighter');
  box-shadow: 0 1px 8px rgba(0, 0, 0, .1);
  margin-top: 30px;

  &.no-border {
    border: 0;
  }

  .card-title {
    padding: 15px 0;
    color: color('base');
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .card-content {
    padding: 15px 0;
    overflow: auto;
  }

  .card-title + .card-content {
    padding-top: 0;
  }

  .card-action {
    border-top: 1px solid color('base-lighter');
    padding: 10px 0;
    margin-top: 15px;
  }
}

.stats-card {
  @include u-shadow(2);
  border: 1px solid color('primary-light');
  border-radius: 4px;
  padding: 5px;
  margin-top: 45px;
  width: 200px;
  margin-right: 30px;
  text-align: center;
  background: color('primary-darker');
  color: #fff;

  .sub-section-header {
    font-size: 30px;
    font-weight: bold;
  }

  .sub-section-text {

  }
}

.result-card {
  margin-bottom: 60px;
  padding: 30px;
  border: 1px solid #d9e8f6;
  border-radius: 4px;

  h2, h2 a {
    position: relative;
    color: #07648d;
    text-transform: capitalize;
    margin-top: 5px;
  }

  h3 {
    color: #162e51;
  }

  img { 
    width: 100%;
    height: auto;
    margin-top: 15px;
  }

  .tags {
    margin-bottom: 30px;
  }

  .additional-information {
    margin-top: 30px;

    p {
      margin-bottom: 5px;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }

    .statistic-title {
      margin-bottom: 0 !important;
    }

    .statistic-value {
      font-size: 24px;
      border-bottom: 0;
      margin: 0;
    }
  }
}
